import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from "@headlessui/react";
import {Notice, NoticeAction, noticeStyles} from "@/types";

type DisplayModalProps = {
    notice: Notice
    dismiss: (timestamp: string) => void
}
export const DisplayModal: React.FC<DisplayModalProps> = ({notice, dismiss}) => {
  let actions: Array<React.ReactNode> = [];
  if (notice.actions && notice.actions.length > 0) {
    if (!notice.style) {
      notice.style = "default";
    }
    actions = notice.actions.map(
      (action: NoticeAction) => <button 
        className={clsx([noticeStyles[notice.style as string].button as string, "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto" ])}
        onClick={()=>{
          action.func();
          dismiss(notice.timestamp!);
        }} 
        key={action.name}
        > {action.name} </button>
    )
  }
  const backgroundClose = () => {
    if (notice.easyDismiss) {
      dismiss(notice.timestamp!);
    }
    return null;
  };
  return (
    <Dialog open={true} onClose={backgroundClose} > 
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                { noticeStyles[notice.style].icon }
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                { notice.message && 
                  <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    { notice.message }
                  </DialogTitle> 
                }
                { notice.content &&
                  <div className="text-sm text-gray-500">
                  { notice.content }
                  </div>
                }
                <div className="mt-2">
                </div>
              </div>
            </div>
            <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
              { actions }
              <button
                type="button"
                data-autofocus
                onClick={() => {dismiss(notice.timestamp!)}}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
