import React from "react";
import {Button} from "@/components/atoms/Button";
import {Input} from "@/components/atoms/Input";
import {Link} from "@/components/atoms/Link";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import {useTranslation} from "@/hooks";

interface RegisterProps {
    onSubmit: () => void;
}

const CreateUser: React.FC<RegisterProps> = ({onSubmit}) => {
    const {t} = useTranslation();
    const {newOrgState: {user_fullname, user_nickname, user_email, user_password}, setUserFullname, setUserNickname, setUserEmail, setUserPassword} = useNewOrgStore();
    const registerHandler = async (e: any) => {
        e.preventDefault();
        onSubmit();
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-10 w-auto"
                     src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"/>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    {t("Create your account")}
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={registerHandler} method="POST">
                    <div>
                        <label htmlFor="user_fullname" className="block text-sm font-medium leading-6">
                            {t("Fullname")}
                        </label>
                        <div className="mt-2">
                            <Input id="user_fullname" name="user_fullname" type="input" value={user_fullname} onChange={(e: any) => {setUserFullname(e.target.value)}} />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="preferred" className="block text-sm font-medium leading-6">
                            {t("Preferred Name")}
                        </label>
                        <div className="mt-2">
                            <Input id="preferred" name="preferred_name" type="input" value={user_nickname}  onChange={(e: any) => {setUserNickname(e.target.value)}}  />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="user_email" className="block text-sm font-medium leading-6">
                            {t("Email")}
                        </label>
                        <div className="mt-2">
                            <Input id="user_email" name="user_email" type="email" value={user_email}  onChange={(e: any) => {setUserEmail(e.target.value)}}  required />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="user_password" className="block text-sm font-medium leading-6">
                            {t("Password")}
                        </label>
                        <div className="mt-2">
                            <Input id="user_password" name="user_password" type="password" value={user_password}  onChange={(e: any) => {setUserPassword(e.target.value)}}  required />
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            {t("Register")}
                        </Button>
                    </div>
                    <hr/>
                    {/* TODO: SSO buttons*/}
                </form>

                <p className="mt-10 text-center text-sm text-gray-400">
                    {t("Already have an account?")}&nbsp;
                    <Link to="./" className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">{t("Login")}</Link>
                </p>
            </div>
        </div>
    );
};

export default CreateUser;



