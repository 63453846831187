export const EndpointAuthLogin = () => { return "/api/v1/auth/login" }
export const EndpointAuthLogout = () => { return "/api/v1/auth/logout" }
export const EndpointAuthRefresh = () => { return "/api/v1/auth/refresh" }
export const EndpointAuthVerifyEmail = () => { return "/api/v1/auth/verify" }
export const EndpointInviteInfo = (code: string) => { return `/api/v1/invites/${code}` }
export const EndpointInviteResend = (orgUuid: string, inviteUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/invites/${inviteUuid}` }
export const EndpointInviteDelete = (orgUuid: string, inviteUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/invites/${inviteUuid}` }
export const EndpointOrganizationApp = (orgUuid: string, orgAppUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps/${orgAppUuid}` }
export const EndpointOrganizationAppExternalUsers = (orgUuid: string, orgAppUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps/${orgAppUuid}/external_users` }
export const EndpointOrganizationAppUsersUpdate = (orgUuid: string, orgAppUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps/${orgAppUuid}/users` }
export const EndpointOrganizationApps = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps` }
export const EndpointOrganizationAppCreate = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps` }
export const EndpointOrganizationAppUsers = (orgUuid: string, orgAppUuid: string) => { return `/api/v1/organizations/${orgUuid}/apps/${orgAppUuid}/users` }
export const EndpointOrganizationBillingCheckoutInit = (orgUuid: string, plan: string, quantity: number) => { return `/api/v1/organizations/${orgUuid}/billing/checkout?plan=${plan}&quantity=${quantity}` }
export const EndpointOrganizationBillingPortal = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/billing/portal` }
export const EndpointOrganizationBillingSessionInit = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/billing/session` }
export const EndpointOrganizationBillingSubscription = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/billing/status` }
export const EndpointOrganizationBillingUpdateEmail = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/billing/email` }
export const EndpointOrganizationCodeGetAll = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/codes` }
export const EndpointOrganizationCodeGetOne = (orgUuid: string, codeUuid: string) => { return `/api/v1/organizations/${orgUuid}/codes/${codeUuid}` }
export const EndpointOrganizationCodeCreate = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/codes` }
export const EndpointOrganizationCreate = () => { return "/api/v1/organizations" }
export const EndpointOrganizationGet = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}` }
export const EndpointOrganizationUpdate = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}` }
export const EndpointOrganizationGroup = (orgUuid: string, groupUuid: string) => { return `/api/v1/organizations/${orgUuid}/groups/${groupUuid}` }
export const EndpointOrganizationGroupUpdate = (orgUuid: string, groupUuid: string) => { return `/api/v1/organizations/${orgUuid}/groups/${groupUuid}` }
export const EndpointOrganizationGroups = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/groups` }
export const EndpointOrganizationGroupCreate = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/groups` }
export const EndpointOrganizationUsers = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/users` }
export const EndpointOrganizationUserUpdate = (orgUuid: string, userUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/${userUuid}` }
export const EndpointOrganizationUserGet = (orgUuid: string, userUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/${userUuid}` }
export const EndpointOrganizationUserCreate = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/users` }
export const EndpointOrganizationUserInvite = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/invites` }
export const EndpointOrganizationUserInviteGetAll = (orgUuid: string) => { return `/api/v1/organizations/${orgUuid}/users/invites` }
export const EndpointOrganizationInit = () => { return "/api/v1/organizations/init" }
