import React, {useEffect} from "react";
import {useAppStateStore} from "@/stores/keyforgeState";

// This page is used to set Feature Flags within state
// You add a flag by using query parameters

// Example: /feature?flags=test1 will add the test1 flag
// Multiple flags can be added at the same time, separated by commas:
// Multi-Flag Example: /feature?flags=test1,test2,test3

export const Feature: React.FC = () => {
    const {setFeatureFlags} = useAppStateStore();
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const flags = queryParams.get("flags");
        if (flags) {
          const flagArray = flags.split(",");
          setFeatureFlags(flagArray);
          console.log(`${flagArray.length} flags added`);
        }
      }, [setFeatureFlags]); // Only run on initial mount
    return (
        <></>
    );
}
