import React from "react";
import {useTranslation} from "@/hooks";
import {AppInstallButton} from "@/components/molecules";
import {OrganizationApp, OrganizationAppStatus} from "@/types";

interface PageOrganizationAppsConfigureConfigSlackProps {
    app: OrganizationApp
}

export const PageOrganizationAppsConfigureConfigSlack: React.FC<PageOrganizationAppsConfigureConfigSlackProps> = ({app}) => {
    const {t} = useTranslation();

    let showInstallButton = false;
    let showAuthButton = false;

    if (app) {
        // if status is uninstalled, show the install button
        showInstallButton = app.status === OrganizationAppStatus.Uninstalled;

        // if app user is an admin, but has no user access token, show the install button, but we should change the label
        showAuthButton = (app.status === OrganizationAppStatus.NotAuthorized || (!app.current_user.has_sso_auth && app.current_user.app_admin));
    }
    
    const renderAppInfo = () => {
        if (!app) {
            return;
        }

        return (
            <div className="bg-white p-4 mt-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg md:col-span-2">
                <div className="border-b border-gray-200 pb-5 mb-5 sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{t("General")}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {t("Information and authorization for this app")}
                            </p>
                        </div>
                    </div>
                </div>
                {(showInstallButton || showAuthButton) && (
                    <div>
                        <p>{t(`To install this app, you must first login with Slack and grant Key Forge some permissions. We try to keep permissions as minimal as possible, only using what is required for the app to function properly`)}</p>
                        <div className="inline-block mx-auto"><AppInstallButton orgApp={app} authOnly={showAuthButton && !showInstallButton}/></div>
                    </div>
                )}
                {(!showInstallButton && app?.external_name) && (
                    <div>
                        <span className="font-medium">Workspace:</span>
                        <div className="block bg-gray-200 shadow-md border rounded-lg border-gray-400 p-2 my-2">{app.external_name}.slack.com</div>
                    </div>
                )}
            </div>
        )
    }

    return renderAppInfo()
};