const NormalizationForm: string = 'NFKD';
const RandomCharset: string = 'ABCDEFGHJKMNPQRSTUVWXYZ123456789'; // Removed ambiguous characters

export const normalizeIdentity = (email: string): string => {
    return email.toLowerCase().trim();
}

export const normalizePassword = (password: string): string => {
    return password.trim().normalize(NormalizationForm);
}

export const generateRandomString = (length: number): string => {
    const secureRandomChar = () => {
        const randomValues = new Uint8Array(1);
        crypto.getRandomValues(randomValues);
        return RandomCharset.charAt(randomValues[0] % RandomCharset.length);
    };

    let randomString = '';
    for (let i = 0; i < length; i++) {
        randomString += secureRandomChar();
    }
    return randomString;
}

export const formatSecretKey = (secretKey: string): string => {
    if (secretKey.length !== 32) {
        throw new Error("Invalid secret key length. Must be 32 characters.");
    }
    return secretKey.substring(0, 2) + '-' +
        secretKey.substring(2, 8) + '-' +
        secretKey.substring(8, 14) + '-' +
        secretKey.substring(14, 20) + '-' +
        secretKey.substring(20, 26) + '-' +
        secretKey.substring(26, 32);
}

export const createSecretKeyMask = (version: string, accountId: string): string => {
    const lengthRemaining = 32 - version.length - accountId.length;
    // call formatSecretKey, passing version, accountId and lengthRemaining X's
    return formatSecretKey(version + accountId + 'X'.repeat(lengthRemaining));
}
