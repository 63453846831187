import React from "react";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import {UrlLogin} from "@/constants";
import {useTranslation} from "@/hooks";
import {Button, InlinePlanSelector, Input, Link} from "@/components/atoms";

interface OrganizationProps {
    onSubmit: () => void;
}

const CreateOrganization: React.FC<OrganizationProps> = ({onSubmit}) => {
    const {t} = useTranslation();

    const {newOrgState: {org_key, org_label, org_plan, org_billing_email}, setOrgKey, setOrgLabel, setOrgPlan, setOrgBillingEmail} = useNewOrgStore();
    const registerOrganizationHandler = async (e: any) => {
        e.preventDefault();
        // validate data
        // if all good, call onSubmit
        onSubmit();
    }

    const setLabel = (e: any) => {
        const label = e.target.value;
        setOrgLabel(label);
        setOrgKey(label.toLowerCase().trim().replace(/\s/g, "-").replace("/\-\-/g", "-"));
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight">
                    {t("Create new organization")}
                </h2>
                <p className='text-md mt-4'>
                    {t("Fill out the form below to create a new organization for yourself or a group of people. If you are looking to join an existing organization, please contact your Administrator to be invited to an existing account.")}
                </p>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={registerOrganizationHandler} method="POST">
                    <div>
                        <label htmlFor="fullname" className="block text-sm font-medium leading-6">
                            {t("Organization Name")}
                        </label>
                        <div className="mt-2">
                            <Input id="fullname" name="fullname" type="input" value={org_label} onChange={setLabel}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="preferred" className="block text-sm font-medium leading-6">
                            {t("Workspace ID")}
                        </label>
                        <div className="mt-2">
                            <Input id="preferred" name="preferred_name" type="input" value={org_key} onChange={(e: any) => {
                                setOrgKey(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                            {t("Plan")}
                        </label>
                        <div className="mt-2">
                            <InlinePlanSelector value={org_plan} onChange={setOrgPlan} />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                            {t("Billing Email")}
                        </label>
                        <div className="mt-2">
                            <Input id="email" name="email" type="email" value={org_billing_email} onChange={(e: any) => {
                                setOrgBillingEmail(e.target.value)
                            }} required/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            {t("Next Step: Create Account")}
                        </Button>
                    </div>
                    <p className="mt-10 text-center text-sm text-gray-400">
                        <Link to={UrlLogin()} className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">Temp Login Link</Link> (need to formalize where creating new org lives)
                    </p>
                </form>
            </div>
        </div>
    );
};

export default CreateOrganization;



