import React, {useEffect, useState} from "react";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {EndpointOrganizationCreate, EndpointOrganizationInit, StepCreateOrg} from "@/constants";
import {formatSecretKey} from "@/utils/common";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import CreateUser from "./CreateUser";
import CreateOrganization from "./CreateOrganization";
import {AccountDetails} from "@/components/molecules";
import {AuthCreds, Group, GroupType, GroupUser} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState.ts";

type PageOrganizationCreateProps = {};

export const PageOrganizationCreate: React.FC<PageOrganizationCreateProps> = () => {
    const [worker] = useWorker();
    const {t} = useTranslation();
    const {newOrgState: {org_key, org_label, org_billing_email, user_fullname, user_nickname, user_email, user_password}, load} = useNewOrgStore();
    const {addOrg} = useAppStateStore();
    const [tempSecretKey, setTempSecretKey] = useState<string>("");
    const [step, setStep] = useState(StepCreateOrg.StepOrgForm);

    const {execute: register, loading} = useHttpPost();
    const {execute: init} = useHttpGet();

    useEffect(() => {
        const payload = {
            "org_key": "keyforge",
            "org_label": "Key Forge",
            "org_plan": "",
            "org_billing_email": "test@keyforge.io",
            "user_fullname": "Dummy McDumface",
            "user_nickname": "Roberto",
            "user_email": "test@keyforge.io",
            "user_password": "qwerty123",
        }
        load(payload);
    }, [])

    const renderOverview = () => {
        return (<AccountDetails orgLabel={org_label} orgKey={org_key} email={user_email} secretKey={tempSecretKey} heading={t("Your new Organization has been created!")} />);
    }

    const createAccount = async () => {
        if (!worker) {
            // TODO: Add error handling
            return
        }
        const initData = await init(EndpointOrganizationInit(), {
            excludeAuthHeader: true,
        });
        const accountId = initData.account_id;
        const salt = initData.salt;

        const orgData = await worker.CreateOrg({
            orgKey: org_key,
            email: user_email,
            password: user_password,
            accountId: accountId,
            salt: salt,
            version: initData.version,
        });

        const hashedPassword = await worker.HashPassword(user_password);

        const newGroupCreds = await worker.CreateGroup(org_key)


        // setup default groups
        const personalGroup: Group = {
            label: "Personal",
            type: GroupType.Private,
            credentials: newGroupCreds.groupCreds,
            group_users: [{
                credentials: newGroupCreds.groupUserCreds,
            } as GroupUser]
        } as Group;

        const generalGroup: Group = {
            label: "General",
            description: "Default group for shared codes",
            type: GroupType.Shared,
            credentials: newGroupCreds.groupCreds,
            group_users: [{
                credentials: newGroupCreds.groupUserCreds,
            } as GroupUser]
        } as Group;

        const response = await register(EndpointOrganizationCreate(), {
            key: org_key,
            label: org_label,
            billing_email: org_billing_email,
            user: {
                account_id: accountId,
                fullname: user_fullname,
                nickname: user_nickname,
                email: user_email,
                password: hashedPassword,
                public_key: orgData.encodedPublicKey,
                private_key: orgData.encryptedPrivateKey,
                iv: orgData.privateKeyIv,
                salt: salt,
                groups: [personalGroup],
            },
            groups: [generalGroup],
        }, {
            excludeAuthHeader: true,
            credentials: 'include',
            isJSON: true,
        })

        addOrg(response.organization, response.user, {access_token: response.access_token, expiration: response.expiration} as AuthCreds)

        setTempSecretKey(formatSecretKey(orgData.secretKey));
        setStep(StepCreateOrg.StepOverview);
    }

    const renderStep = () => {
        switch (step) {
            case StepCreateOrg.StepOrgForm:
                return <CreateOrganization onSubmit={() => {
                    setStep(StepCreateOrg.StepUserForm)
                }}></CreateOrganization>
            case StepCreateOrg.StepUserForm:
                return <CreateUser onSubmit={createAccount}></CreateUser>
            case StepCreateOrg.StepOverview:
                return renderOverview();
        }
    }

    return (
        <main className="flex min-h-screen flex-col items-center justify-between p-24">
            {loading && <div>{t("Creating organization")}</div>}
            {renderStep()}
        </main>
    );
}
