import React from "react";
import {useTranslation} from "@/hooks";


interface PageOrganizationAppsConfigureConfigWebProps {

}

export const PageOrganizationAppsConfigureConfigWeb: React.FC<PageOrganizationAppsConfigureConfigWebProps> = ({}) => {
    const {t} = useTranslation();

    const renderAppInfo = () => {
        return (
            <div className="bg-white p-4 mt-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg md:col-span-2">
                <div className="border-b border-gray-200 pb-5 mb-5 sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{t("General")}</h1>
                        </div>
                    </div>
                </div>
                <p>
                    <p>{t("Current there are not any settings for the web app. Should that change in the future, this is where they will be")}</p>
                </p>
            </div>
        )
    }

    return renderAppInfo()
};