import React, {useEffect, useState} from "react";
import {useTranslation, useWorker} from "@/hooks";
import {useAppStateStore} from "@/stores/keyforgeState"
import {Button} from "@/components/atoms/Button";
import {Input} from "@/components/atoms/Input";

import {KeyForgeStateOrg, OrgInfo} from "@/types"

type AuthenticateProps = {
    orgKey: string
    onSuccess(): void
    onError(err: string): void
}

export const Authenticate: React.FC<AuthenticateProps> = ({orgKey, onSuccess, onError}) => {
    const [worker] = useWorker();
    const {t} = useTranslation();
    const {getOrgByKey} = useAppStateStore();
    const [password, setPassword] = useState("");
    const [org, setOrg] = useState<KeyForgeStateOrg | null>(null);
    const [orgInfo, setOrgInfo] = useState<OrgInfo>({} as OrgInfo);

    useEffect(() => {
        setPassword("qwerty123");
    }, [])

    useEffect(() => {
        if (!worker) {
            return;
        }
        if (orgKey) {
            setOrg(getOrgByKey(orgKey))
            worker.GetOrgInfo(orgKey).then(setOrgInfo)
        }
    }, [orgKey, worker])

    const authenticateHandler = async (e: any) => {
        e.preventDefault();
        if (!worker) {
            onError("Worker not ready")
            return;
        }
        if (!org || !orgInfo) {
            onError("Failed to get org info")
            return
        }
        try {
            console.log("Attempt to authenticate")
            await worker.Authenticate(orgKey, password);
            onSuccess();
        } catch (e: any) {
            onError(e.message)
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                {/*<img className="mx-auto h-10 w-auto"*/}
                {/*     src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"/>*/}
                <h2 className="mb-2 text-center text-2xl font-bold leading-9 tracking-tight">
                    Authenticate
                </h2>
                <p>Maybe show the org logo</p>
                <p>Org name: {org?.organization?.label}</p>
                <p>Secret Key: {orgInfo.maskedSecretKey}</p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={authenticateHandler} method="POST">
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                            Password</label>
                        <div className="mt-2">
                            <Input id="password" name="password" type="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            {t("Unlock")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
