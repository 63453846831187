import React, {useEffect, useState} from "react";
import {Switch} from "@headlessui/react";
import {GroupType} from "@/constants"
import {useTranslation} from "@/hooks";
import {Group} from "@/types"
import {Button} from "@/components/atoms";

export type UserGroupManagerGroupState = {
    groupUuid: string;
    toggled: boolean;
    roles: number;
}

type UserGroupManagerProps = {
    groupList: Array<Group>;
    userGroups: {[key: string]: UserGroupManagerGroupState};
    onChange: (groupUserState: UserGroupManagerGroupState) => void;
}

const groupFilterFunction = (filter: string) => {
    return (group: Group) => {
        if (group.type === GroupType.Private.toString()) {
            return false
        }
        if (filter === "") {
            return true;
        }
        return group.label.toLowerCase().includes(filter.toLowerCase());
    }
}

export const UserGroupManager: React.FC<UserGroupManagerProps> = ({groupList, userGroups, onChange}) => {
    const {t} = useTranslation();
    const [filter, setFilter] = useState<string>("");
    const [filteredGroupList, setFilteredGroupList] = useState<Array<Group>>(groupList || []);

    useEffect(() => {
        setFilteredGroupList(groupList.filter(groupFilterFunction(filter)));
    }, [groupList, filter])

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = e.target.value;
        setFilter(newFilter);
        setFilteredGroupList(groupList.filter(groupFilterFunction(newFilter)));
    }

    const handleResetFilter = (e: any) => {
        e.preventDefault();
        setFilter("");
    }

    const handleGroupStatusToggle = (group: Group) => {
        let userGroup = userGroups[group.uuid];
        if (!userGroup) {
            userGroup = {
                groupUuid: group.uuid,
                toggled: true,
                roles: 0,
            } as UserGroupManagerGroupState;
        } else {
            userGroup.toggled = !userGroup.toggled;
        }
        onChange(userGroup)
    }

    const handleGroupRoleChange = (group: Group, newRole: number) => {
        let userGroup = userGroups[group.uuid];
        if (!userGroup) {
            userGroup = {
                groupUuid: group.uuid,
                toggled: true,
                roles: newRole === 1 ? 1 : 0, // only two roles right now, this ensures that the role is either 0 or 1
            } as UserGroupManagerGroupState;
        } else {
            userGroup.roles = newRole === 1 ? 1 : 0;
        }
        onChange(userGroup)
    }

    const renderGroupList = () => {
        if (filteredGroupList.length === 0 && groupList.length > 0) {
            return (
                <li className="py-4 w-full text-center">
                    <p className="text-sm font-medium">{t("No groups found with filter")}</p>
                    <div className="p-4">
                        <Button className="cursor-pointer" onClick={handleResetFilter}>{t("Clear filter")}</Button>
                    </div>
                </li>
            );
        }
        return (filteredGroupList || []).map((group) => {
            const userGroup = userGroups[group.uuid];
            const isToggled = userGroup !== undefined && userGroup.toggled;
            return (
                <li key={group.uuid} className="flex items-center justify-between space-x-3 py-4">
                    <div className="flex min-w-0 flex-1 items-center space-x-3">
                        <div className="flex-shrink-0">
                            <Switch
                                checked={isToggled}
                                onChange={() => { handleGroupStatusToggle(group)}}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                            >
                                <span className="sr-only">Use setting</span>
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                />
                            </Switch>
                        </div>
                        {/*<div className="flex-shrink-0">*/}
                        {/*    <img alt="" src={group.settings.} className="h-10 w-10 rounded-full"/>*/}
                        {/*</div>*/}
                        <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium">{group.label}</p>
                            <p className="truncate text-sm font-medium">{group.description}</p>
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <label htmlFor={`role-${group.uuid}`} className="block text-sm font-medium leading-6">
                                {t("Role")}
                            </label>
                            <select
                                id={`role-${group.uuid}`}
                                name={`role-${group.uuid}`}
                                value={userGroup?.roles || 0}
                                onChange={(e) => handleGroupRoleChange(group, parseInt(e.target.value))}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                <option value={0}>None</option>
                                <option value={1}>Admin</option>
                            </select>
                        </div>
                    </div>
                </li>);
            }
        );
    }

    return (
        <div className="mx-auto text-black">
            <div>
                <div className="text-center">
                    <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                        className="mx-auto h-12 w-12"
                    >
                        <path
                            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <h2 className="mt-2 text-base font-semibold leading-6">{t("Manage user's group access")}</h2>
                    <p className="mt-1 text-sm pb-2">
                        {t("Below you can manage which groups a user has access to and what permissions they have in the group.")}
                    </p>
                </div>
                <label htmlFor="email" className="sr-only">
                    {t("Group Name")}
                </label>
                <input
                    id="group"
                    name="group"
                    type="text"
                    placeholder="Filter groups"
                    onChange={handleFilterChange}
                    value={filter}
                    className="block w-full text-black rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </div>
            <div className="mt-10">
                <ul role="list" className="mt-4">
                    {renderGroupList()}
                </ul>
            </div>

        </div>
    );
}