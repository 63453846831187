import React from "react";
import {useAppStateStore} from "@/stores/keyforgeState";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export const PageNotifications: React.FC = () => {
  const {getNotices, dismissNotice, addNotice } = useAppStateStore();
  let notifications = getNotices();
  return (
    <div className="flow-root">
        {notifications.length > 0 ? 
          <ul role="list" className="-mb-8">
            {/* TODO: Refactor to use the Alert atom */}
            { notifications.map((event) => (
              <li key={event.timestamp}>
                <div className="relative pb-8">
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className="flex h-8 w-8 items-center justify-center rounded-full ring-8"
                      >
                        <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-white" />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p>{ event.type }</p>
                        <p className={clsx("text-sm text-gray-500")}>
                          { event.message + ' ' }
                          {/* <a href={event.href} className="font-medium text-gray-900">
                            {event.target}
                          </a> */}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={event.timestamp}>{ new Date(parseInt(event.timestamp!)).toLocaleDateString() }<br />{ new Date(parseInt(event.timestamp!)).toLocaleTimeString() }</time>
                      </div>
                      <div>
                        <button 
                          className="rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                          onClick={()=>{dismissNotice(event.timestamp!)}}
                        >Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )) }
          </ul>
        : 
        <div className="container flex flex-row justify-center content-center">
          <div className="md:basis-1/2 sm:basis-full text-center border-blue-800 border-dotted border-2 py-5 px-2">
            <h1 className="mt-2 text-sm font-semibold text-gray-100">No Notifications</h1>
            <p className="mt-1 text-sm text-gray-500">You are all caught up.</p>
            
          </div>
        </div>
        }
        <div>
        <button 
              onClick={()=>{
                addNotice({
                  style:"warning", 
                  type:"notify", 
                  actions: [{
                    name: "Trigger Console Log", 
                    func: ()=>{console.log("Action func for action test within Notify is triggered")}
                  }], 
                  message:"This Notice will self-distruct in 15 seconds, unless you specify autoDismiss = false"
                })
              }}
              className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-300 hover:bg-blue-50"
            >Create An Notify</button>
        <button 
              onClick={()=>{
                addNotice({
                  style:"error", 
                  type:"modal", 
                  actions: [{
                    name: "Trigger Console Log", 
                    func: ()=>{console.log("Action func for action test within Modal is triggered")}
                  }],
                  easyDismiss: true,
                  message:"This Modal must be manually dismissed.",
                  content: <div>
                      <p>It can take custom elements, like this text!</p>
                      <ul className="list-disc">
                        <li>Or a list</li>
                        <li>of things!</li>
                      </ul>
                    </div>
                })
              }}
              className="rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-300 hover:bg-blue-50"
            >Create An Modal</button>
        </div>
    </div>
  )
}