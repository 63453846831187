import React from 'react'
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";
import { DataInteractive as HeadlessDataInteractive } from '@headlessui/react'

const LinkBase = (props: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const { children, to, ...rest } = props;
    return (
        <HeadlessDataInteractive>
            <ReactRouterLink to={to} {...rest} ref={ref}>
                {props.children}
            </ReactRouterLink>
        </HeadlessDataInteractive>
    )
}

export const Link = React.forwardRef(LinkBase) as React.FC<LinkProps>