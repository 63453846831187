import React, {useEffect, useState} from "react";
import {ArrowUturnLeftIcon, ArrowRightIcon, WrenchScrewdriverIcon} from "@heroicons/react/24/solid";
import {EndpointOrganizationAppExternalUsers, EndpointOrganizationUsers, EndpointOrganizationAppUsers, EndpointOrganizationAppUsersUpdate} from "@/constants/endpoints.constant.ts"
import {useHttpGet, useHttpPost, useTranslation} from "@/hooks";
import {useAppLinkageStore} from "@/stores/appLinkage.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {ExternalUser, ExternalUserLinkage, ExternalUserLinkageAction, Organization, OrganizationApp, OrganizationAppUser} from "@/types";
import {Button, LoadingIcon} from "@/components/atoms";
import {AppLinkagePopover} from "@/components/molecules";

type AppUserManagerProps = {
    organization: Organization;
    organizationApp: OrganizationApp;
}

type AppGroupPayloadItem = {
    app_user_uuid?: string;
    link?: string;
    unlink?: boolean;
    invite?: {
        email: string;
        fullname: string;
    }
}

export const AppUserManager: React.FC<AppUserManagerProps> = ({organization, organizationApp}) => {
    const {t} = useTranslation();
    const [manageUsers, setManageUsers] = useState(false);
    const {linkages, unset, reset} = useAppLinkageStore();
    const {addNotice} = useAppStateStore();
    const {execute: getAppExternalUsers, data: externalUsers, loading: loadingExternalUsers} = useHttpGet(organization.key);
    const {execute: getAppUsers, data: appUsers} = useHttpGet(organization.key);
    const {execute: getOrgUsers, data: orgUsers} = useHttpGet(organization.key);
    const {execute: updateOrgAppUsers} = useHttpPost(organization.key);

    const hasChanges = Object.values(linkages).filter((linkage) => {
        return linkage.action !== ExternalUserLinkageAction.None
    }).length > 0;

    useEffect(() => {
        getAppExternalUsers(EndpointOrganizationAppExternalUsers(organization.uuid, organizationApp.uuid), {}).then((result) => {
            reset(organizationApp.uuid, result?.items || []);
        })
        getOrgUsers(EndpointOrganizationUsers(organization.uuid), {})
    }, [manageUsers])


    useEffect(() => {
        getAppUsers(EndpointOrganizationAppUsers(organization.uuid, organizationApp.uuid), {})
    }, [organization.uuid])

    const cancelChanges = () => {
        reset(organizationApp.uuid, externalUsers?.items || []);
        setManageUsers(false);
        addNotice({type: "notify", style: "warning", message: "Pending app changes cancelled"})
    }

    const applyChanges = () => {
        const payload: Array<AppGroupPayloadItem> = [];

        Object.values(linkages).forEach((linkage) => {
            if (linkage.action !== ExternalUserLinkageAction.None) {
                const item: AppGroupPayloadItem = {} as AppGroupPayloadItem;
                switch (linkage.action) {
                    case ExternalUserLinkageAction.Linked:
                        item["app_user_uuid"] = linkage.linked_user.uuid;
                        item["link"] = linkage.external_user.id;
                        break;
                    case ExternalUserLinkageAction.Unlink:
                        item["app_user_uuid"] = linkage.linked_user.uuid;
                        item["unlink"] = true;
                        break;
                    case ExternalUserLinkageAction.Invite:
                        item["invite"] = linkage.invite;
                        break;
                }
                payload.push(item);
            }
        })

        updateOrgAppUsers(EndpointOrganizationAppUsersUpdate(organization.uuid, organizationApp.uuid), payload, {}).then(() => {
            setManageUsers(false);
            addNotice({type: "notify", style: "success", message: t("App users updated")})
            return getAppUsers(EndpointOrganizationAppUsers(organization.uuid, organizationApp.uuid), {})
        }).catch((err) => {
            addNotice({type: "notify", style: "error", message: err.message || t("Failed to update app users")})
        })
    }

    const renderUserList = () => {
        if (manageUsers) {
            return null;
        }
        return (
            <>
                <ul role="list" className="divide-y divide-gray-100">
                    {appUsers?.items?.map((app_user: OrganizationAppUser) => {
                        return (
                            <li key={app_user.uuid} className="relative flex justify-between gap-x-6 py-5">
                                <div className="flex min-w-0 gap-x-4">
                                    {app_user.settings.avatar_url && <img alt="" src={app_user.settings.avatar_url} className="h-12 w-12 flex-none rounded-full bg-gray-50"/>}
                                    <div className="min-w-0 flex-auto">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                            {app_user.settings.display_name}
                                        </p>
                                        <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                            {app_user.settings.name}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <ArrowRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400"/>
                                </div>
                                <div className="flex items-center gap-x-4">
                                    <div className="flex min-w-0 gap-x-4">
                                        {app_user.user?.settings?.avatar_url && <img alt="" src={app_user.user.settings?.avatar_url} className="h-12 w-12 flex-none rounded-full bg-gray-50"/>}
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                {app_user.user?.fullname}
                                            </p>
                                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                {app_user.user?.nickname}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                    {appUsers?.items.length === 0 && (
                        <li className="py-4 w-full text-center">
                            <p className="text-sm font-medium">{t("Currently no authorized users")}</p>
                        </li>
                    )}
                </ul>
            </>
        )
    }

    const renderSummaryLine = (linkage: ExternalUserLinkage): string => {
        switch (linkage.action) {
            case ExternalUserLinkageAction.Linked:
                return t(`Linking ${linkage.linked_user.fullname} app user -> ${linkage.external_user.fullname}`);
            case ExternalUserLinkageAction.Unlink:
                return t(`Unlinking ${linkage.linked_user.fullname}. Access will be revoked`);
            case ExternalUserLinkageAction.Invite:
                return t(`Inviting ${linkage.invite.email} and linking to app user -> ${linkage.external_user.fullname}. This will use a seat.`);
        }
        return ""
    }

    const renderSummary = () => {
        if (!manageUsers || !hasChanges) {
            return null;
        }

        // keep track of linking and unlinking of users
        // keep track of inviting users
        // keep track of how many additional seats completing the action will require

        return (
            <div className="bg-blue-100 p-4 mt-3 rounded-xl border border-gray-200">
                <h2 className="text-lg font-semibold text-gray-800 mb-2">Change Summary</h2>
                <ul role="list" className="divide-y divide-gray-700 list-disc list-inside text-gray-700">
                    {Object.values(linkages).filter((linkage) => {
                        return linkage.action !== ExternalUserLinkageAction.None
                    }).map((linkage) => {
                            const external_user = linkage.external_user
                            return (
                                <li
                                    key={external_user.id}
                                    className="flex flex-wrap items-center justify-between gap-x-6 gap-y-2 py-2 sm:flex-nowrap"
                                >
                                    {/*<div className="flex-none rounded-full p-1">*/}
                                    {/*/!*    checkbox?*!/*/}
                                    {/*</div>*/}
                                    <p className="text-sm font-semibold leading-6 text-gray-900">
                                        {external_user.fullname}
                                    </p>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <p>
                                            {renderSummaryLine(linkage)}
                                        </p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        <ArrowUturnLeftIcon onClick={() => {
                                            unset(external_user)
                                        }} className="h-5 w-5 flex-none inline-block text-red-500 cursor-pointer" aria-hidden="true"/>
                                    </div>
                                </li>
                            )
                        }
                    )}
                </ul>
                <div>
                    <div className="border-t border-gray-400 p-2 mt-2 sm:flex sm:items-center sm:justify-between">
                        <p className="text-base text-gray-900">Appling changes will increase your paid seats by X</p>
                        <div className="mt-2 flex sm:ml-4 sm:mt-0">
                            <Button color="zinc" className="inline-block cursor-pointer flex items-center" type="button" onClick={cancelChanges}>{t("Cancel")}</Button>
                            <Button color="blue" className="inline-block cursor-pointer flex items-center ml-2" type="button" onClick={applyChanges}>{t("Apply")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderUserEditor = () => {
        if (!manageUsers || !linkages || Object.entries(linkages).length !== externalUsers?.items?.length) {
            return null;
        }

        return (
            <div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-1/2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                {externalUsers?.items?.length > 0 && externalUsers.items.map((externalUser: ExternalUser, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img alt="" src={externalUser.avatar_thumb_url} className="h-11 w-11 rounded-full"/>
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">{externalUser.fullname}</div>
                                                        <div className="mt-1 text-gray-500">{externalUser.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <AppLinkagePopover externalUser={externalUser} orgUsers={orgUsers?.items || []}/>
                                            </td>
                                            {linkages[externalUser.id].action !== ExternalUserLinkageAction.None && (
                                                <td className="relative whitespace-nowrap pl-3 text-right text-sm font-medium pr-4">
                                                    <ArrowUturnLeftIcon onClick={() => {
                                                        unset(externalUser)
                                                    }} className="h-5 w-5 flex-none inline-block text-red-500 cursor-pointer" aria-hidden="true"/>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                                {loadingExternalUsers && (
                                    <tr>
                                        <td colSpan={3} className="py-5 text-center text-sm font-medium text-gray-500">
                                            <div className="flex text-center items-center min-h-16">
                                                <LoadingIcon/>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        loadingExternalUsers && (
                            <div className="flex text-center items-center min-h-16">
                                <LoadingIcon/>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">{t("Manage user app access")}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {manageUsers ? t("Manage how organization users are linked to the external users in this app") : t("List of app users currently configured for this app")}
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    {!manageUsers && (
                        <Button className="inline-block cursor-pointer flex items-center" type="button" onClick={() => setManageUsers(true)}>
                            {t("Manage users")} <WrenchScrewdriverIcon aria-hidden="true" className="inline-block h-5 w-5 m-2 text-gray-400"/>
                        </Button>
                    )}
                    {(manageUsers && !hasChanges) && (
                        <Button color="zinc" className="inline-block cursor-pointer flex items-center" type="button" onClick={cancelChanges}>
                            {t("Cancel")}
                        </Button>
                    )}
                </div>
            </div>
            {renderUserList()}
            {renderSummary()}
            {renderUserEditor()}
        </div>
    )
}