import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {EndpointInviteInfo, EndpointOrganizationUserCreate, StepCreateUser} from "@/constants"
import { FaceFrownIcon } from "@heroicons/react/24/solid";
import {useNewOrgStore} from "@/stores/newOrgState.ts";
import {CreateUser} from "@/components/organisms";
import {AuthCreds, Group, GroupType, GroupUser, Invite, Organization} from "@/types";
import {formatSecretKey} from "@/utils/common.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {AccountDetails} from "@/components/molecules";

export const PageInviteSignup: React.FC = () => {
    const {t} = useTranslation();
    const [worker] = useWorker();
    const {newOrgState: {user_fullname, user_nickname, user_email, user_password}, setUserFullname, setUserEmail} = useNewOrgStore();
    const {addOrg} = useAppStateStore();
    const {code} = useParams();
    const {execute: getCodeInfo, data: codeInfo, loading: codeInfoLoading} = useHttpGet<Invite>("");
    const org = codeInfo?.organization || {} as Organization;
    const {execute: createAccount} = useHttpPost(org?.key || "");
    const [tempSecretKey, setTempSecretKey] = useState<string>("");
    const [step, setStep] = useState(StepCreateUser.StepUserForm);

    useEffect(() => {
        if (!code) {
            return;
        }
        getCodeInfo(EndpointInviteInfo(code), {}).then((invite) => {
            setUserEmail(invite.email);
            setUserFullname(invite.fullname);
        })
        // TODO: Add error handling
    }, [code]);

    const onSubmitHandler = async() => {
        if (!worker) {
            // TODO: Add error handling
            return
        }
        if (!codeInfo || !org) {
            // TODO: Add error handling
            return;
        }
        const initData = codeInfo?.init_data;
        if (!initData) {
            // TODO: Add error handling
            return
        }
        const accountId = initData.account_id;
        const salt = initData.salt;
        const hashedPassword = await worker.HashPassword(user_password);

        const orgData = await worker.CreateUser({
            orgKey: org.key,
            email: user_email,
            password: user_password,
            accountId: accountId,
            salt: salt,
            version: initData.version,
        });

        const newGroupCreds = await worker.CreateGroup(org.key);
        const personalGroup: Group = {
            label: "Personal",
            type: GroupType.Private,
            credentials: newGroupCreds.groupCreds,
            group_users: [{
                credentials: newGroupCreds.groupUserCreds,
            } as GroupUser]
        } as Group;
        
        const response = await createAccount(EndpointOrganizationUserCreate(org.uuid), {
            code: code,
            account_id: accountId,
            fullname: user_fullname,
            nickname: user_nickname,
            email: user_email,
            password: hashedPassword,
            public_key: orgData.encodedPublicKey,
            private_key: orgData.encryptedPrivateKey,
            iv: orgData.privateKeyIv,
            salt: salt,
            groups: [personalGroup],
        }, {
            excludeAuthHeader: true,
            credentials: 'include',
            isJSON: true,
        });

        addOrg(response.organization, response.user, {access_token: response.access_token, expiration: response.expiration} as AuthCreds)

        setTempSecretKey(formatSecretKey(orgData.secretKey));
        setStep(StepCreateUser.StepOverview);
    };

    const renderProblem = (reason: string) => {
        return (
            <div className="text-center mt-16">
                <h1 className="mt-2 text-xl font-semibold text-white">{t("There's a problem with your invite")}</h1>
                <div className="mt-8 text-sm text-white">
                    <FaceFrownIcon aria-hidden="true" className="mx-auto h-16 w-16 text-white-300 mb-6"/>
                    <p className="mt-2">
                        {reason}</p>
                    <p className="mt-2">
                        {t("Contact your organization admin to get this sorted out!")}
                    </p>
                </div>
            </div>
        )
    }

    if (step === StepCreateUser.StepOverview) {
        return (<AccountDetails orgLabel={org.label} orgKey={org.key} email={user_email} secretKey={tempSecretKey} heading={t("Your account has been created!")}/>);
    }

    if (codeInfoLoading || !codeInfo) {
        return (<div>{t("Loading...")}</div>);
    }

    if (codeInfo.status !== "pending") {
        return renderProblem(t("It looks like the invite has already been used."));
    }

    if (new Date(codeInfo.expires_at).getTime() < new Date().getTime()) {
        return renderProblem(t("It looks like the invite has expired."));
    }

    return (
        <div>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                {org?.settings?.logo && <img className="mx-auto h-10 w-auto" src={org.settings.logo} alt={`${org?.label}'s logo`}/>}
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                    {t("Create your account")}
                </h2>
            </div>
            <CreateUser onSubmit={onSubmitHandler} submitLabel={t("Create")}/>
        </div>
    );
}
