import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
type CodeDropzoneProps = {
    onDrop: (files: File[]) => void;
    enablePaste?: boolean;
};

export const Dropzone: React.FC<CodeDropzoneProps> = ({ onDrop, enablePaste }) => {
    const onDropCallback = useCallback((acceptedFiles: File[]) => {
        onDrop(acceptedFiles);
    }, [onDrop]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropCallback });

    useEffect(() => {
        if (enablePaste) {
            const handlePaste = (event: ClipboardEvent) => {
                const items = event.clipboardData?.items || [];
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if (item.type.indexOf("image") !== -1) {
                        const file = item.getAsFile();
                        if (!file) {
                            continue;
                        }
                        onDropCallback([file]);
                        // Clear the clipboard
                        if (event.clipboardData) {
                            event.clipboardData.clearData();
                        }
                        break;
                    }
                }
            };

            window.addEventListener("paste", handlePaste);
            return () => {
                window.removeEventListener("paste", handlePaste);
            };
        }
    }, [onDrop]);

    return (
        <div {...getRootProps()} className='border-2 border-primary-300 rounded-lg border-dashed text-center p-12 bg-primary-300 bg-opacity-25'>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
            { enablePaste && <p>Or paste an image from your clipboard</p> }
        </div>
    );
};