import React, { useRef } from "react";

type SimpleFileUploadProps = {
    onChange(base64: string | ArrayBuffer): void;
    label?: string;
    id?: string;
}

export const SimpleFileUpload: React.FC<SimpleFileUploadProps> = ({ label = "Upload File", id = "simple-file-upload", onChange }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                if (onChange) {
                    onChange(base64 || "");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <input
                type="file"
                id={id}
                ref={inputRef}
                onChange={handleFileChange}
                className="hidden"
            />
            <label htmlFor={id} className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">{label}</label>
        </div>
    );
}