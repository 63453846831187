import React, {useEffect} from "react";
import {useOutletContext, useLocation} from "react-router-dom";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {useHttpGet, useHttpPost, useHttpDelete, useTranslation} from "@/hooks";
import {EndpointInviteDelete, EndpointInviteResend, EndpointOrganizationUserInviteGetAll, EndpointOrganizationUsers, UrlOrganizationUsersInvite} from "@/constants";
import {OrgOutlet} from "@/types";
import {Link} from "@/components/atoms"
import {InviteList, UserList} from "@/components/molecules"
import clsx from "clsx";
type PageOrganizationUserManageProps = {}

export const PageOrganizationUserManage: React.FC<PageOrganizationUserManageProps> = () => {
    const {t} = useTranslation();
    const {organization} = useOutletContext<OrgOutlet>();
    const { hash} = useLocation();
    const { addNotice } = useAppStateStore();
    const currentTab = hash?.length > 0 ? hash.substring(1) : "users";

    const {execute: getUsers, data: users} = useHttpGet(organization?.key);
    const {execute: getInvites, data: invites} = useHttpGet(organization?.key);
    const {execute: resendInvite} = useHttpPost(organization?.key);
    const {execute: deleteInvite} = useHttpDelete(organization?.key);

    useEffect(() => {
        if (!organization) {
            return;
        }
        getUsers(EndpointOrganizationUsers(organization.uuid), {});
        getInvites(EndpointOrganizationUserInviteGetAll(organization.uuid), {});
    }, [organization]);

    const handleResend = (uuid: string) => {
        resendInvite(EndpointInviteResend(organization.uuid, uuid), {}, {}).then(() => {
            addNotice({
                style:"success",
                type:"notify",
                easyDismiss: true,
                message: t("Invite resent successfully.")
            })
        }, () => {
            addNotice({
                style:"error",
                type:"notify",
                easyDismiss: true,
                message: t("Failed to resend invite, try again later.")
            })
        });
    }

    const handleDelete = (inviteUuid: string) => {
        addNotice({
            style:"default",
            type:"modal",
            actions: [{
                name: "Confirm delete",
                func: ()=>{
                    console.log("This would have deleted the invite");
                    deleteInvite(EndpointInviteDelete(organization.uuid, inviteUuid), {}).then(() => {
                        // success, pull latest invite list
                        addNotice({
                            style:"success",
                            type:"notify",
                            easyDismiss: true,
                            message: t("Invite deleted successfully."),
                        })
                        return getInvites(EndpointOrganizationUserInviteGetAll(organization.uuid), {});
                    }, () => {
                        addNotice({
                            style:"error",
                            type:"notify",
                            easyDismiss: true,
                            message: t("Failed to delete invite, try again later."),
                        })
                    });
                }
            }],
            easyDismiss: true,
            message: t("Are you sure you want to delete this invite?"),
            // content: <div>
            //     <p>It can take custom elements, like this text!</p>
            //     <ul className="list-disc">
            //         <li>Or a list</li>
            //         <li>of things!</li>
            //     </ul>
            // </div>
        })
    }

    const renderHeading = () => {
        return (
            <div>
                <div className="flex flex-wrap items-center gap-6 sm:flex-nowrap mb-8">
                    <h1 className="text-base font-semibold leading-7">Manage Users</h1>
                    <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                        <Link to="#users" className={clsx("", {["underline"]: currentTab === "users"})}>
                            User List
                        </Link>
                        <Link to="#invites" className={clsx("", {["underline"]: currentTab === "invites"})}>
                            Invite List
                        </Link>
                    </div>
                    <Link to={UrlOrganizationUsersInvite(organization?.key)} className="ml-auto flex items-center py-2">
                        <button
                            type="button"
                            className="flex rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            <PlusIcon aria-hidden="true" className="-ml-1.5 h-5 w-5" />
                            <span className="ml-1">{t("Invite User")}</span>
                        </button>
                    </Link>
                </div>
            </div>
        )
    }

    const renderUserList = () => {
        if (currentTab !== "users") {
            return;
        }

        return (
            <div>
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6">Users</h1>
                        <p className="mt-2 text-sm">
                            {t("A list of all the users in your organization.")}
                        </p>
                    </div>
                </div>
                <UserList organization={organization} users={users?.items || []}/>
            </div>
        )
    }

    const renderInviteList = () => {
        if (currentTab !== "invites") {
            return;
        }
        return (
            <div>
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6">Invites</h1>
                        <p className="mt-2 text-sm">
                            {t("A list of outstanding invites to your organiztion. Invites expire after 7 days.")}
                        </p>
                    </div>
                </div>
                <InviteList onResend={handleResend} onDelete={handleDelete} invites={invites?.items || []}/>
            </div>
        )
    }
    return (
        <div className="p-2">
            {renderHeading()}
            {renderUserList()}
            {renderInviteList()}
        </div>
    );
}
