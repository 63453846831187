import {Group, GroupUser} from "./group";

export enum UserStatus {
    Active = "active",
    Unverified = "unverified", // email not verified
    Pending = "pending", // user has accepted invite or completed recovery and is awaiting group assignment
    Recovery = "recovery", // user who needs to regenerate their credentials
    Suspended = "suspended", // user who has has their access suspended
    Archived = "archived", // user who is in a "deleted state"
}

export type User = {
    uuid: string;
    organization_uuid: string;
    account_id: string;
    fullname: string;
    nickname: string;
    email: string;
    roles: number;
    status: UserStatus;
    settings: UserSettings
    group_users: Array<GroupUser>;
    groups: Array<Group>;
    public_key: string;
    expires_at: string;
    created_at: string;
    updated_at: string;
}

export type UserSettings = {
    timezone: string;
    language: string;
    avatar_data: string;
    avatar_url: string;
    name: string;
    display_name: string
}

export const getDefaultUser = (): User => {
    return {
        uuid: "",
        organization_uuid: "",
        account_id: "",
        fullname: "",
        nickname: "",
        email: "",
        roles: 0,
        status: UserStatus.Active,
        settings: getDefaultUserSettings(),
        group_users: [],
        groups: [],
        public_key: "",
        expires_at: "",
        created_at: "",
        updated_at: "",
    } as User
};
export const getDefaultUserSettings = (): UserSettings => {
    return {
        timezone: "",
        language: "",
        avatar_data: "",
        avatar_url: "",
        name: "",
        display_name: ""
    }
}
