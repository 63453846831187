import React, {ReactNode} from "react";
import {useAppStateStore} from "@/stores/keyforgeState";

type FeatureFlagProps = {
    flag: string,
    children: ReactNode,
    alternative?: ReactNode
}
// Feature Flag Organism
// Takes in a Flag key, checks state to see if it is active.
// If it's active, it displays the children
// If it isn't active, it displays alternative content or nothing at all


export const FeatureFlag: React.FC<FeatureFlagProps> = ({flag, children, alternative}) => {
    const {getFeatureFlags} = useAppStateStore();
    // TODO: Add Features array inside store
    if (getFeatureFlags().indexOf(flag)>=0){
        return (<>{children}</>)
    } else if (alternative) {
        return (<>{alternative}</>)
    } else {
        // FF Content is a placeholder to see the rendering is correct.
        // If this is working and it still says FF, that's a mistake
        return (
            <>FF</>
        );
    }
};
