import { create } from "zustand";
import { persist } from "zustand/middleware"

import { getDefaultKeyForgeState, AuthCreds, KeyForgeState, KeyForgeStateOrg, Organization, User, Notice} from "@/types";

const stateStorageName = "keyforge-state";

export type AppStateStore = {
    keyForgeState: KeyForgeState;
    addOrg: (org: Organization, user: User, creds: AuthCreds) => void;
    getOrgs: () => Array<KeyForgeStateOrg>;
    removeOrgByUuid: (orgUuid: string) => void;
    getOrgByUuid: (orgUuid: string) => KeyForgeStateOrg | null;
    removeOrgByKey: (orgKey: string) => void;
    getOrgByKey: (orgKey: string) => KeyForgeStateOrg | null;
    getNotices: () => Array<Notice>;
    addNotice: (notice: Notice) => void;
    dismissNotice: (noticeTimestamp: string) => void;
    setAuthUserAndCreds: (orgKey: string, user: User, creds: AuthCreds) => void;
    getDarkMode: () => string;
    setDarkMode: (mode: string) => void;
    getFeatureFlags: () => string[];
    setFeatureFlags: (flags: string[]) => void;
    getLastOrgKey: () => string;
    getLastOrAnyOrg: () => KeyForgeStateOrg | null;
    setLastOrgKey: (key: string) => void;
    reset: () => void;
    load: (appState: KeyForgeState) => void;
};

const useAppStateStoreBase = persist((set: any, get: any) => ({
    keyForgeState: getDefaultKeyForgeState(),
    addOrg: (org: Organization, user: User, creds: AuthCreds) => {
        set((state : any) => ({ keyForgeState: { ...state.keyForgeState, organizations: [...state.keyForgeState.organizations.filter((orgState: Organization) => orgState.uuid !== org.uuid), {
            organization: org,
            user: user,
            creds: creds,
        } as KeyForgeStateOrg] } }));
    },
    getOrgs: () => {
        return get().keyForgeState.organizations;
    },
    removeOrgByUuid: (orgUuid: string) => {
        set((state : any) => ({ keyForgeState: {
                ...state.keyForgeState, organizations: state.keyForgeState.organizations.filter((org: Organization) => org.uuid !== orgUuid)
            }
        }));
    },
    getOrgByUuid: (orgUuid: string): KeyForgeStateOrg | null => {
        for (const org of get().keyForgeState.organizations) {
            if (org.organization.uuid === orgUuid) {
                return org;
            }
        }
        return null;
    },
    removeOrgByKey: (orgKey: string) => {
        set((state : any) => ({ keyForgeState: {
                ...state.keyForgeState, organizations: state.keyForgeState.organizations.filter((org: Organization) => org.key !== orgKey)
            }
        }));
    },
    getOrgByKey: (orgKey: string): KeyForgeStateOrg | null  => {
        for (const org of get().keyForgeState.organizations) {
            if (org.organization.key === orgKey) {
                return org;
            }
        }
        return null;
    },
    getNotices: () => {
        return get().keyForgeState.notices;
    },
    addNotice: (notice: Notice) => {
        set((state : any) => ({ keyForgeState: { ...state.keyForgeState, notices: 
            [ ...state.keyForgeState.notices, {
                ...notice, 
                timestamp: Date.now().toString(),
                easyDismiss: notice.easyDismiss || true,
                //headline: notice.headline || "Notification", // TODO: Is this still used? Don't see it in the Notice type
                style: notice.style || "default"
            } as Notice ] } }));
    },
    dismissNotice: (noticeTimestamp: string) => {
        set((state : any) => ({ keyForgeState: {
            ...state.keyForgeState, notices: state.keyForgeState.notices.filter((notice: Notice) => notice.timestamp !== noticeTimestamp)
            }
        }));
    },
    setAuthUserAndCreds: (orgKey: string, user: User, creds: AuthCreds) => {
        set((state : any) => ({
            keyForgeState: {
                ...state.keyForgeState,
                organizations: state.keyForgeState.organizations.map((org: KeyForgeStateOrg) => {
                    if (org.organization.key === orgKey) {
                        return {
                            ...org,
                            user: user,
                            creds: creds,
                        }
                    }
                    return org;
                })
            }
        }));
    },
    getDarkMode: () => {
        return get().keyForgeState.preferences.darkMode;
    },
    setDarkMode: (mode: string) => {
        set((state: any) => ({ 
            keyForgeState: {
                ...state.keyForgeState, 
                preferences: {
                    ...state.keyForgeState.preferences, 
                    darkMode: mode 
                }
            }
        }));
        switch(mode){
            case "light":
                document.documentElement.classList.remove("dark");
                break;
            case "dark":
                document.documentElement.classList.add("dark")
                break;
            case "system":
                window.matchMedia('(prefers-color-scheme: dark)').matches ? document.documentElement.classList.add("dark") : document.documentElement.classList.remove("dark");
                break;
            default:
                console.log("Unknown Dark Mode Setting, Ignoring Input");
                return;
        };
    },
    getFeatureFlags: () => {
        return get().keyForgeState.preferences.features || [];
    },
    setFeatureFlags: (flags: string[]) => {
        set((state: any) => ({ 
            keyForgeState: {
                ...state.keyForgeState, 
                preferences: {
                    ...state.keyForgeState.preferences, 
                    // Everything below is just to get a deduped array. Javascript cannot be a real language. Spread two arrays, add to a new Set, then spread that new set in an array.
                    features: [...new Set([...state.keyForgeState.preferences.features, ...flags])] 
                }
            }
        }));
    },
    getLastOrgKey: () => {
        return get().keyForgeState.preferences.lastOrgKey;
    },
    getLastOrAnyOrg: (): KeyForgeStateOrg | null => {
        const lastOrgKey = get().getLastOrgKey()
        if (lastOrgKey) {
            return get().getOrgByKey(lastOrgKey);
        }
        const orgList = get().getOrgs();
        if (orgList.length > 0) {
            return orgList[0];
        }
        return null;
    },
    setLastOrgKey: (key: string) => {
        set((state: any) => ({ 
            keyForgeState: {
                ...state.keyForgeState, 
                preferences: {
                    ...state.keyForgeState.preferences, 
                    lastOrgKey: key
                }
            }
        }));
    },
    reset: () => {},
    load: (state: KeyForgeState) => {
        set(() => ({ keyForgeState: state }));
    },
}),{
    name: stateStorageName,
    version: 1,
    migrate: (state: any) => {
        if (state.alerts === undefined) {
            state.alerts = []
        }
        return state;
    }
})

export const useAppStateStore = create<AppStateStore>()(useAppStateStoreBase);

// The following function is for keeping browser tabs in sync. It will monitor local storage
// However it is currently unused because it'll have an issue of an infinite loop
// Might be able to do something as simple as a singleton with a counter increment prior to writing
// have handleStorageChange func below check if this var is > 0, if so decrement and return
// otherwise sync the state with the latest value from localStorage. Would have to ensure setting that
// state doesn't trigger a localStorage sync, and then causing an infinite loop
// another possible solution might be to set an id on load, and have it save to localStorage with that
// id, if the sync func below sees that the id is the same, it'll just return after doing nothing
// otherwise it'll update the state. Either will probably require customizing the storage class
// function useSyncAppState() {
//     useEffect(() => {
//         const handleStorageChange = (event) => {
//             if (event.key === stateStorageName) {
//                 const newState = JSON.parse(event.newValue);
//                 useAppStateStore.setState(newState.state);
//             }
//         };
//
//         window.addEventListener("storage", handleStorageChange);
//
//         return () => {
//             window.removeEventListener("storage", handleStorageChange);
//         };
//     }, []);
// }