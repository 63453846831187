import clsx from "clsx";
import { StopCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";

type BillboardProps = {
    style: 'alert' | 'warn' | 'info',
    header: string,
    desc?: string,
}

const StyleColors = {
    alert: 'bg-red-500 border-red-800 text-slate-900',
    warn: 'bg-secondary-300 border-secondary-500 text-slate-800',
    info: 'bg-primary-700 border-primary-500 text-white',
};

const StyleIcons = {
    alert: <StopCircleIcon className="h-full w-full" />,
    warn: <ExclamationTriangleIcon className="h-full w-full" />,
    info: <InformationCircleIcon className="h-full w-full" />,
};

export const Billboard: React.FC<BillboardProps> = ({style, header, desc}) => {
    return (
    <div className={clsx('mx-auto py-12 px-6 max-w-prose rounded-md border-4', StyleColors[style])}>
        <div className="grid grid-cols-6">
            <div className="h-12">
                {StyleIcons[style]}
            </div>
            <h1 className="col-span-5 inline-block align-text-bottom text-xl font-bold">
                {header}
            </h1>
        </div>
        {desc && <p className="px-6 pt-4 font-semibold">{desc}</p>}
    </div>);
}