import {Notice} from "./notice";
import {Organization} from "./organization";
import {Preferences} from "./preferences";
import {User} from "./user";

export type KeyForgeState = {
    organizations: Array<KeyForgeStateOrg>;
    notices: Array<Notice>;
    preferences: Preferences;
}

export type KeyForgeStateOrg = {
    organization: Organization;
    user: User | null;
    creds: AuthCreds | null;
}

export type AuthCreds = {
    access_token: string;
    expiration: number;
}

export const getDefaultKeyForgeState = (): KeyForgeState => ({
    organizations: [],
    notices: [],
    preferences: {
        darkMode: "system",
        lastOrgKey: "",
        features: []
    }
});