import React, {useEffect, useCallback, useState} from "react";
import {EndpointOrganizationCodeGetAll} from "@/constants/endpoints.constant.ts";
import {Code} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {FetchRequest, HttpMethod} from "@/utils/fetch";
import {CodeNavigator} from "@/components/organisms";

export const PageDashboard: React.FC = () => {
    const orgList = useAppStateStore(state => state.getOrgs());
    const [codeList, setCodeList] = useState<any[]>([])
    const fetchAllCodes = useCallback(() => {
        const requests = orgList.map(org => {
            const fetchRequest = new FetchRequest(HttpMethod.Get, org.organization.key);
            return fetchRequest.execute(EndpointOrganizationCodeGetAll(org.organization.uuid), {}).then(async (result) => {
                if (result.ok) {
                    const json = await result.json();
                    return json?.items.map((item: Code) => {
                        item.organization = org.organization; // cache the organization we loaded the code from on the code
                        return item;
                    }) || [];
                }
                return [];
            })
        });

        let codeResult: any = [];
        return Promise.all(requests).then(results => {
            results.forEach((codes) => {
                codeResult = [...codeResult, ...codes];
            });
            setCodeList(codeResult);
        });
    }, [orgList]);

    useEffect(() => {
        fetchAllCodes().then(console.log).catch(console.error);
    }, [])

    return (
        <div className="text-white">
            <CodeNavigator codes={codeList}/>
        </div>
    );
}
