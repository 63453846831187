import {create} from "zustand";
import {getDefaultUser, Group, GroupUser, User} from "@/types";

export type NewUserStateStore = {
    user: User;
    setFullName: (fullname: string) => void;
    setNickname: (nickname: string) => void;
    setRoles: (roles: number) => void;
    setTimezone: (timezone: string) => void;
    setLanguage: (language: string) => void;
    setAvatarData: (avatarData: string) => void;
    setAvatarUrl: (avatarUrl: string) => void;
    setGroupUsers: (groupUsers: Array<GroupUser>) => void;
    reset: () => void;
    load: (newGroupData: User) => void;
};


export const useUserStateStore =  create<NewUserStateStore>((set: any) => ({
    user: getDefaultUser(),
    setFullName: (fullname: string) => {
        set((state: any) => ({user: {...state.user, fullname: fullname}}));
    },
    setNickname: (nickname: string) => {
        set((state: any) => ({user: {...state.user, nickname: nickname}}));
    },
    setRoles: (roles: number) => {
        if (typeof roles === "string") {
            roles = parseInt(roles);
        }
        set((state: any) => ({user: {...state.user, roles: roles}}));
    },
    setTimezone: (timezone: string) => {
        set((state: any) => ({user: {...state.user, settings: {...state.user.settings, timezone: timezone}}}));
    },
    setLanguage: (language: string) => {
        set((state: any) => ({user: {...state.user, settings: {...state.user.settings, language: language}}}));
    },
    setAvatarData: (avatarData: string) => {
        set((state: any) => ({user: {...state.user, settings: {...state.user.settings, avatar_data: avatarData}}}));
    },
    setAvatarUrl: (avatarUrl: string) => {
        set((state: any) => ({user: {...state.user, settings: {...state.user.settings, avatar_url: avatarUrl}}}));
    },
    setGroupUsers: (groupUsers: Array<GroupUser>) => {
        set((state: any) => ({user: {...state.user, group_users: groupUsers}}));
    },
    reset: () => {
        set(() => ({user: getDefaultUser()}));
    },
    load: (state: User) => {
        state.group_users = []; // default to empty list (since it won't be returned)
        if (!state.groups) {
            state.groups = [];
        }
        state.groups.forEach((group: Group) => {
            if (!group.group_users) {
                // this shouldn't happen, the user shouldn't have the group if they don't have a group user.. but just in case
                return;
            }
            state.group_users.push(group.group_users[0])
        })
        set(() => ({user: state}));
    },
}));