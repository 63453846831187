import {Group} from "./group";
import {User} from "./user";

export enum OrganizationStatus {
    Active = "active",
    Inactive = "inactive",
    Suspended = "suspended",
}
export type Organization = {
    uuid: string;
    key: string;
    label: string,
    status: OrganizationStatus;
    billing_email: string;
    settings: OrganizationSettings;
    created_at: string;
    updated_at: string;

    apps: Array<OrganizationApp>;
    groups: Array<Group>;
    users: Array<User>;
}

export type OrganizationApp = {
    uuid: string;
    organization_uuid: string;
    app_id: string;
    external_name: string;
    label: string;
    expires_at: string;
    status: OrganizationAppStatus;
    created_at: string;
    updated_at: string;

    app: App;
    app_users: Array<User>;
    current_user: OrganizationAppCurrentUserStatus;
    group: Group; // default group for new codes

    public_key: string;
    oauth_url: string
}

export type OrganizationAppCurrentUserStatus = {
    has_sso_auth: boolean;
    app_admin: boolean;
}

export type OrganizationAppUser = {
    uuid: string;
    organization_app_uuid: string;
    org_user_uuid: string;
    settings: OrganizationAppUserSettings
    user: User | null;
    public_key: string;
}

export type OrganizationSettings = {
    logo: string; // base64 encoded image
    logo_source: string // whether the user uploaded a custom logo or we generated it
    bg_color: string; // bg color with # prefix
}

export type OrganizationInitData = {
    salt: string;
    account_id: string;
    version: string;
}

export enum OrganizationAppStatus {
    Installed = "installed",
    Uninstalled = "uninstalled",
    Disabled = "disabled",
    NotAuthorized = "not_authorized",
    NeedsSetup = "needs_setup",
}

export type OrganizationAppUserSettings = {
    avatar_data: string;
    avatar_url:  string;
    name: string;
    display_name: string;
}

export type OrganizationAppGroup = {
    uuid: string;
    organization_app_uuid: string;
    group_uuid: string;
    roles: number;
    settings: object;
    credentials: string;

    group: Group;

    // only used in the UI, not used in on the backend
    granted?: boolean;
    original?: boolean;
    existing?: boolean;
}

export const getDefaultOrganization = (): Organization => {
    return {
        uuid: "",
        key: "",
        label: "",
        status: OrganizationStatus.Inactive,
        billing_email: "",
        settings: {
            logo: "",
            logo_source: "generated",
            bg_color: "#FFFFFF",
        },
        created_at: "",
        updated_at: "",
        apps: [],
        groups: [],
        users: [],
    }
}

// Ideally everything below would be a separate file
// But putting it here because it is so closely tied to organization
// And doing so prevents a circular reference

export type App = {
    id: string;
    label: string,
    enabled: "active" | "inactive" | "suspended";
    order: number;
    created_at: string;
    updated_at: string;

    organization_app: OrganizationApp;
}

export enum AppIds {
    Web = "web",
    Desktop = "desktop",
    Mobile = "mobile",
    Slack = "slack",
    Teams = "teams",
}

export type ExternalUser = {
    id: string;
    fullname: string
    preferred: string,
    email: string,
    avatar_thumb_url: string,
    avatar_large_url: string,
    user: User | null,
}

export enum ExternalUserLinkageAction {
    None = "none",
    Invite = "invite",
    Unlink = "unlink",
    Linked = "linked",
    // Purposely not including a "relink" option because it simplifies everything. Without it, there is no need to track the previous state of the user.
    // You will always know that if the action is "link" then the user is not linked, and if the action is "unlink" then the user is currently linked.
    // If action != "none" and it has a linked user in the payload, you know it isn't persisted yet
}

export type ExternalUserInvite = {
    email: string;
    fullname: string;
}

export type ExternalUserLinkage = {
    action: ExternalUserLinkageAction;
    invite: ExternalUserInvite;
    invite_email: string;
    linked_user: User;
    external_user: ExternalUser;
}