export enum SubscriptionPeriod {
    Monthly = "monthly",
    Annual = "annual"
};

export type SubscriptionPlan = {
    id: string,
    desc: string,
    benefits: string[],
    label: string,
    maxQuantity: number,
    billingPeriods: SubscriptionPeriod[],
    baseCost: number[],
    unitCost?: number[],
    includedUnits: number,
    maxUnits?: number
}

export const SubscriptionPlans: SubscriptionPlan[] = [
    {
        id: "personal",
        desc: "Access your own MFA codes on your own terms, retrieve them within any Key Forge app. Completely private to you, regardless of how many organizations you are a part of.",
        benefits: ["Access MFA codes vis Slack or Web", "Private, Individual MFA", "No MFA Code limits", "Limited to 1 User, up to 5 devices"],
        label: "Personal",
        maxQuantity: 1,
        billingPeriods: [SubscriptionPeriod.Annual],
        baseCost: [0,2000],
        includedUnits: 1,
        maxUnits: 1
    },
    {
        id: "team",
        desc: "Give employees private and sharable MFA access. Includes your first 20 Users, then $1/month for each additional user.",
        benefits: ["Access MFA codes vis Slack or Web", "Private, Individual MFA", "Sharable, Team MFA Access", "Real-Time Audit Logging"],
        label: "Team Basics",
        maxQuantity: 100,
        billingPeriods: [SubscriptionPeriod.Monthly, SubscriptionPeriod.Annual],
        baseCost: [2000, 24000],
        unitCost: [100, 1200],
        includedUnits: 20,
    }
]
