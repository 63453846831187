import React from "react";
import {DisplayModal, DisplayNotify} from "@/components/molecules";
import {useAppStateStore} from "@/stores/keyforgeState";
import {Notice} from "@/types";

export const NoticeOverlay: React.FC = () => {
    const {getNotices, dismissNotice} = useAppStateStore();
    let ActiveNotices = getNotices();
    let Modals = ActiveNotices.filter((notice: Notice) => notice.type === "modal");
    let Notifys = ActiveNotices.filter((notice: Notice) => notice.type === "notify");
    //let Banners = ActiveNotices.filter((notice: Notice) => notice.type === "banner");
    let ActiveNotify = Notifys.map((data) => <DisplayNotify notice={data} dismiss={dismissNotice} key={data.timestamp}/>)
    // Expand to control modals as well

    if (Modals.length > 0) {
        return <DisplayModal notice={Modals[0]} dismiss={dismissNotice} />
    }
    if (Notifys.length > 0) {
        return <div className="absolute bottom-5 right-5">{ActiveNotify}</div>
    }
    return null;
}
