import React, {useState} from "react"
import {Radio, RadioGroup} from '@headlessui/react'
import clsx from "clsx";

const plans = [
    {id: "personal", name: 'Personal', description: 'Single user'},
    {id: 'team_basic', name: 'Team Basic', description: 'Any team size'},
    // {name: 'Enterprise', priceMonthly: '$249', priceYearly: '$2490', limit: 'Unlimited active job postings'},
]
export type PlanItem = {
    id: string,
    name: string,
    description: string,
}

type InlinePlanSelectorProps = {
    value: string,
    // options: Array<PlanItem>
    onChange: (value: string) => void
}

export const InlinePlanSelector: React.FC<InlinePlanSelectorProps> = ({value, onChange}) => {
    const [selected, setSelected] = useState(value)

    const renderPlanItems = () => {
        return plans.map((plan: PlanItem, planIdx) => (
            <Radio
                key={plan.name}
                value={plan.id}
                aria-label={plan.name}
                aria-description={`${plan.name} - ${plan.description}`}
                className={clsx(
                    planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                    planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                    'group relative flex cursor-pointer flex-col border border-gray-200 p-4 focus:outline-none data-[checked]:z-10 data-[checked]:border-indigo-200 data-[checked]:bg-indigo-50 md:grid md:grid-cols-3 md:pl-4 md:pr-6',
                )}
                onChange={() => onChange(plan.id)}
            >
                <span className="flex items-center text-sm">
                    <span
                    aria-hidden="true"
                    className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-300 bg-white group-data-[checked]:border-transparent group-data-[checked]:bg-indigo-600 group-data-[focus]:ring-2 group-data-[focus]:ring-indigo-600 group-data-[focus]:ring-offset-2"
                    >
                        <span className="h-1.5 w-1.5 rounded-full bg-white"/>
                    </span>
                    <span className="ml-3 font-medium text-gray-900 group-data-[checked]:text-indigo-900">{plan.name}</span>
                </span>
                <span className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                    <span className="text-gray-500 group-data-[checked]:text-indigo-700">{plan.description}</span>
                </span>
            </Radio>
        ))
    }
    return (
        <fieldset aria-label="Pricing plans">
            <RadioGroup value={selected} onChange={setSelected} className="relative -space-y-px rounded-md bg-white">
                {renderPlanItems()}
            </RadioGroup>
        </fieldset>
    )
}

