import React from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "@/hooks";
import {UrlOrganizationUsers} from "@/constants";
import {InviteUser} from "@/components/organisms"
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {OrgOutlet} from "@/types";

type PageOrganizationUserInviteProps = {}

export const PageOrganizationUserInvite: React.FC<PageOrganizationUserInviteProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { organization } = useOutletContext<OrgOutlet>();
    const {addNotice} = useAppStateStore();

    const onSuccessHandler = () => {
        addNotice({
            style:"success",
            type:"notify",
            easyDismiss: true,
            message: t("User invited successfully.")
        })
        navigate(UrlOrganizationUsers(organization?.key) + "#invites");
    }

    const onFailureHandler = (err: Error) => {
        addNotice({
            style:"error",
            type:"notify",
            easyDismiss: true,
            message: t("Failed to invite user: " + err.message),
        })
    }

    return (
        <div className="">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6">Invite user</h1>
                        <p className="mt-2 text-sm">
                            Inviting a user might impact your billing.
                        </p>
                    </div>
                </div>
                <InviteUser organization={organization} onSuccess={onSuccessHandler} onFailure={onFailureHandler} />
            </div>
        </div>
    );
}
