import React, {useEffect, useState} from "react";
import {useParams, useNavigate, useOutletContext} from "react-router-dom";
import {useHttpGet, useHttpPut, useTranslation, useWorker} from "@/hooks";
import {UserRoles, EndpointOrganizationUserGet, EndpointOrganizationUserUpdate, EndpointOrganizationGroups} from "@/constants";
import {Group, OrgOutlet} from "@/types";
import {Button, Input, Select} from "@/components/atoms";
import {UserGroupManager, UserGroupManagerGroupState} from "@/components/molecules";
import {useUserStateStore} from "@/stores/userState.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";

type PagePageOrganizationUserProps = {}

const generateUserGroupMap = (userGroupList: Array<Group>): {[key: string]: UserGroupManagerGroupState} => {
    if (!userGroupList) {
        return {};
    }
    const result: {[key: string]: UserGroupManagerGroupState} = {};
    userGroupList.forEach((group: Group) => {
        if (!group.group_users) {
            return;
        }
        const groupUser = group.group_users[0];
        result[group.uuid] = {
            groupUuid: group.uuid,
            toggled: true,
            roles: groupUser.roles || 0
        } as UserGroupManagerGroupState
    })
    return result
}

export const PageOrganizationUser: React.FC<PagePageOrganizationUserProps> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [worker] = useWorker();
    const {user_uuid} = useParams();
    const {organization} = useOutletContext<OrgOutlet>();
    const {execute: getUser} = useHttpGet(organization.key);
    const {execute: getGroups, data: groups} = useHttpGet(organization?.key);
    const {execute: updateUser} = useHttpPut(organization?.key);
    const {user, setFullName, setNickname, setRoles, load} = useUserStateStore();
    const {fullname, group_users, nickname, roles} = user;
    const {addNotice} = useAppStateStore();
    const [userGroupMap, setUserGroupMap] = useState<{[key: string]: UserGroupManagerGroupState}>({})

    useEffect(() => {
        loadOrganizationGroups();
    }, [])

    useEffect(() => {
        if (!user_uuid) {
            return;
        }
        getUser(EndpointOrganizationUserGet(organization.uuid, user_uuid), {}).then((resp) => {
            load(resp);
            setUserGroupMap(generateUserGroupMap(resp.groups));
        }).catch(() => {
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to get user data.")
            })
        })
    }, [user_uuid]);

    const loadOrganizationGroups = async () => {
        if (!organization) {
            return;
        }
        getGroups(EndpointOrganizationGroups(organization.uuid), {})
    }

    const handleUserGroupChange = (userGroupState: UserGroupManagerGroupState) => {
        setUserGroupMap({ ...userGroupMap, [userGroupState.groupUuid]: userGroupState });
    }

    const handleUpdateUser = async (e: any) => {
        e.preventDefault();
        if (!worker) {
            console.log("Failed to communicate with worker");
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to update user")
            })
            return
        }

        const groupUsers = [];

        for (const groupState of Object.values(userGroupMap)) {
            if (!groupState.toggled) {
                continue;
            }
            const group = groups.items.find((group: Group) => group.uuid === groupState.groupUuid);

            const currentUser = group.group_users[0]; // should only have 1 user returned, for the user that made the API request.
            const currentGroupUser = group_users.find((groupUser) => {
                return groupUser.group_uuid === groupState.groupUuid
            });
            let credentials = ""
            if (currentGroupUser) {
                credentials = currentGroupUser.credentials
            } else {
                // generate credentials for this group
                credentials = await worker.GrantGroupAccess(organization.key, currentUser.credentials, user.public_key);
            }
            groupUsers.push({
                group_uuid: groupState.groupUuid,
                roles: groupState.roles,
                credentials: credentials
            })
        }

        updateUser(EndpointOrganizationUserUpdate(organization.uuid, user.uuid), {
            fullname: fullname,
            nickname: nickname,
            groups: groupUsers,
            roles: roles
        }, {}).then(() => {
            addNotice({
                style: "success",
                type: "notify",
                easyDismiss: true,
                message: t("User updated successfully.")
            })
            navigate("../users");
        }).catch((err) => {
            addNotice({
                style: "error",
                type: "notify",
                easyDismiss: true,
                message: t("Failed to update user: " + err.message)
            })
        });
    }

    if (!user || !groups) {
        return null;
    }

    return (
        <div className="">
            <h2 className="text-base font-semibold leading-7">{t("Manage user information")}</h2>
            <p className="mt-1 max-w-2xl text-sm leading-6">
                {t("Update user information below.")}
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 sm:pt-1.5">
                        {t("Full Name")}
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <Input type="text" id="fullName" value={fullname} onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 sm:pt-1.5">
                        {t("Nickname")}
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <Input type="text" id="fullName" value={nickname} onChange={(e) => setNickname(e.target.value)}
                        />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 sm:pt-1.5">
                        Organization Role
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <Select value={roles} onChange={(e: any) => { setRoles(e.target.value)}}>
                            <option value={UserRoles.User}>{t("User")}</option>
                            <option value={UserRoles.Admin}>{t("Admin")}</option>
                        </Select>
                    </div>
                </div>

                <div className="py-6 px-2">
                    <UserGroupManager userGroups={userGroupMap} groupList={groups.items || []} onChange={handleUserGroupChange}/>
                </div>
            </div>
            <div className="mt-10 text-right">
                <Button
                    className="flex justify-center rounded-md bg-gray-500 px-3 py-1.5 mr-2 text-sm shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                    onClick={(e: any) => {
                        e.preventDefault();
                        navigate("../users")
                    }}
                >
                    {t("Cancel")}
                </Button>
                <Button
                    className="flex justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={handleUpdateUser}
                >
                    {t("Save")}
                </Button>
            </div>
        </div>
    );
}
