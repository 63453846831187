import React from "react";
import {useHttpPost, useWorker} from "@/hooks";
import {useGroupStore} from "@/stores/groupState.ts"
import {EndpointOrganizationGroupCreate} from "@/constants/endpoints.constant.ts";
import {Button} from "@/components/atoms/Button.tsx";
import {Input} from "@/components/atoms/Input.tsx";
import {Group, GroupUser} from "@/types";
import {useAppStateStore} from "@/stores/keyforgeState.ts";

type CreateGroupProps = {
    orgKey: string
    onCreate: (group: Group) => void
}

export const CreateGroup: React.FC<CreateGroupProps> = ({orgKey, onCreate}) => {
    const [worker] = useWorker();
    const {execute: createGroup} = useHttpPost(orgKey);
    const {group: { label, description}, setLabel, setDescription, reset} = useGroupStore();
    const {getOrgByKey} = useAppStateStore();

    const createHandler = async (e: any) => {
        e.preventDefault();
        if (!worker) {
            // TODO: Add error handling
            return
        }

        try {
            const newGroupCreds = await worker.CreateGroup(orgKey);
            const org = getOrgByKey(orgKey);

            const response = await createGroup(EndpointOrganizationGroupCreate(org?.organization?.uuid!), {
                label: label,
                description: description,
                credentials: newGroupCreds.groupCreds,
                users: [{
                    credentials: newGroupCreds.groupUserCreds,
                } as GroupUser]
            }, {})

            alert(`Group ${response.label} created! *Update me to be a better alert*`)
            reset();
            onCreate(response as Group)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8  text-black">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight">
                    Create New Group
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={createHandler}>
                    <div>
                        <label htmlFor="groupLabel" className="block text-sm font-medium leading-6">
                            Group Label
                        </label>
                        <div className="mt-2">
                            <Input id="groupLabel" name="groupLabel" type="text" required value={label} onChange={(e) => setLabel(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="groupDesc" className="block text-sm font-medium leading-6">
                            Group Description
                        </label>
                        <div className="mt-2">
                            <Input id="groupDesc" name="groupDesc" type="text" required value={description} onChange={(e) => setDescription(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            Create
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};