import { create } from "zustand";

export type NewOrgState = {
    org_key: string;
    org_label: string;
    org_plan: string;
    org_billing_email: string;
    user_fullname: string;
    user_nickname: string;
    user_email: string;
    user_password: string;
}

export const getDefaultNewOrgState = (): NewOrgState => ({
    org_key: "",
    org_label: "",
    org_plan: "",
    org_billing_email: "",
    user_fullname: "",
    user_nickname: "",
    user_email: "",
    user_password: ""
});

export type NewOrgStateStore = {
    newOrgState: NewOrgState;
    setOrgKey: (key: string) => void;
    setOrgLabel: (label: string) => void;
    setOrgPlan: (plan: string) => void;
    setOrgBillingEmail: (email: string) => void;
    setUserFullname: (fullname: string) => void;
    setUserNickname: (nickname: string) => void;
    setUserEmail: (email: string) => void;
    setUserPassword: (password: string) => void;
    reset: () => void;
    load: (newOrgData: NewOrgState) => void;
};

export const useNewOrgStore = create<NewOrgStateStore>((set: any) => ({
    newOrgState: getDefaultNewOrgState(),
    setOrgKey: (key: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, org_key: key } }));
    },
    setOrgLabel: (label: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, org_label: label } }));
    },
    setOrgPlan: (plan: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, org_plan: plan } }));
    },
    setOrgBillingEmail: (email: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, org_billing_email: email } }));
    },
    setUserFullname: (fullname: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, user_fullname: fullname } }));
    },
    setUserNickname: (nickname: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, user_nickname: nickname } }));
    },
    setUserEmail: (email: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, user_email: email } }));
    },
    setUserPassword: (password: string) => {
        set((state : any) => ({ newOrgState: { ...state.newOrgState, user_password: password } }));
    },
    reset: () => {},
    load: (state: NewOrgState) => {
        set(() => ({ newOrgState: state }));
    },
}));