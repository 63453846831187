import { create } from "zustand";
import { AuthRequest} from "@/types";

export type AuthRequestState = {
    request: AuthRequest | null;
    setRequest: (request: AuthRequest | null) => void;
};

export const useAuthRequestStore = create<AuthRequestState>((set: any) => ({
    request: null,
    setRequest: (request: AuthRequest | null) => {
        set(() => ({ request: request }));
    },
}));