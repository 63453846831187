/*

This hook is just a wrapper for the i18n package, however we don't really need to do any of this yet
But if all hardcoded text are using this hook, it 'll make it a lot easier when we do.

 */

// import { useTranslation as usei18nTranslation } from "react-i18next";

export const useTranslation = () =>{
    //const { t, i18n } = usei18nTranslation();

    // const changeLanguage = (language) => {
    //     i18n.changeLanguage(language);
    // };

    const t = (key: string) => {
        // We don't need to do translation yet, just return whatever is passed as is
        return key;
    }
    const changeLanguage = (language: string) => {
        console.log("Changing language to: " + language + " failed, not implemented yet");
    }

    return { t, changeLanguage };
}
