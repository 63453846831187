import {create} from "zustand";
import {getDefaultGroup, Group, GroupApp, GroupStatus, GroupType, OrganizationAppGroup} from "@/types";

export type GroupStateStore = {
    group: Group;
    apps: Array<OrganizationAppGroup>;
    setLabel: (label: string) => void;
    setDescription: (description: string) => void;
    setType: (type: GroupType) => void;
    setIcon: (icon: any) => void;
    setStatus: (status: GroupStatus) => void;
    setCredentials: (credentials: string) => void;
    setApp: (appUuid: string, granted: boolean) => void;
    resetApps: () => void;
    reset: () => void;
    load: (group: Group) => void;
};

export const useGroupStore =  create<GroupStateStore>((set: any, get: any) => ({
    group: getDefaultGroup(),
    apps: [],
    setLabel: (label: string) => {
        set((state: any) => ({group: {...state.group, label: label}}));
    },
    setDescription: (description: string) => {
        set((state: any) => ({group: {...state.group, description: description}}));
    },
    setType: (type: GroupType) => {
        set((state: any) => ({group: {...state.group, type: type}}));
    },
    setIcon: (icon: any) => {
        set((state: any) => ({
            group: {
                ...state.group,
                settings: {
                    ...state.group.settings,
                    icon: icon
                },
            },
        }));
    },
    setStatus: (status: GroupStatus) => {
        set((state: any) => ({group: {...state.group, status: status}}));
    },
    setCredentials: (credentials: string) => {
        set((state: any) => ({group: {...state.group, credentials: credentials}}));
    },
    setApp(orgAppUuid: string, granted: boolean) {
        const apps = get().apps;
        const app = apps.find((app: OrganizationAppGroup) => app.organization_app_uuid === orgAppUuid);
        // if app already found, check if granted status has changed
        if (app) {
            app.granted = granted;
        } else {
            // if app not found, create a new app with the granted status and mark it as not existing
            apps.push({
                organization_app_uuid: orgAppUuid,
                granted: granted,
                existing: false, // new item isn't already existing from the db
            });
        }
        set(() => ({apps: apps}));
    },
    resetApps: () => {
        const apps: any[] = [];
        get().apps?.forEach((app: GroupApp) => {
            apps.push({
                organization_app_uuid: app.organization_app_uuid,
                granted: true,
                existing: true,
            });
        });
        set(() => ({group: get().group, apps: apps}));
    },
    reset: () => {
        set(() => ({group: getDefaultGroup()}));
    },
    load: (state: Group) => {
        const apps: Array<OrganizationAppGroup> = [];
        state.apps?.forEach((app: GroupApp) => {
            apps.push({
                organization_app_uuid: app.organization_app_uuid,
                granted: true,
                original: true,
                existing: true,
            } as OrganizationAppGroup);
        });
        set(() => ({group: state, apps: apps}));
    },
}));