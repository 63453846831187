import React, { useEffect } from "react";
import {useAppStateStore} from "@/stores/keyforgeState";
import {Notice, noticeStyles} from "@/types";
import clsx from "clsx";

type DisplayNotifyProps = {
    notice: Notice
    dismiss: (timestamp: string) => void
}

export const DisplayNotify: React.FC<DisplayNotifyProps> = ({notice, dismiss}) => {

  // Func to handle Notice self-destruct/auto-dismiss
  const {dismissNotice} = useAppStateStore();
  if (notice.easyDismiss) {
    useEffect(() => {
      const timer = setTimeout(() => {
        dismissNotice(notice.timestamp!);
      }, 15000);
    
      return () => clearTimeout(timer);
    }, [dismissNotice, notice.timestamp]);
  }

  return (
    <div className={clsx("rounded-md", noticeStyles[notice.style].background)} key={notice.timestamp}>
      <div className="flex p-4 container">
        <div className="flex-shrink-0 py-2">
          { noticeStyles[notice.style].icon }
        </div>
        <div className="ml-3">
          <div className={clsx("mt-2 text-sm", noticeStyles[notice.style].body)}>
              { notice.message && <h3 className={clsx("text-sm font-medium", noticeStyles[notice.style].header)}> { notice.message } </h3> }
              { notice.content && <div>
                { notice.content }  
              </div>}
              <p className="text-xs">{notice.timestamp}</p>
          </div>
        </div>
      </div>
      <div className={clsx("p-2 container", {"loading-gradient-15s": notice.easyDismiss})}>
          <div className="flex flex-row-reverse">
              {/* <button
                  type="button"
                  className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                  View status
              </button> */}
              <button
                  type="button"
                  className={clsx("ml-3 rounded-md px-2 py-1.5 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2", noticeStyles[notice.style].button)}
                  onClick={()=>{dismiss(notice.timestamp!)}}
                  >
                  Dismiss
              </button>
          </div>
        </div>
      </div>
  )
}
