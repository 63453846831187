import React from "react";
import {useAppStateStore} from "@/stores/keyforgeState";
import {Notice, NoticeAction, noticeStyles} from "@/types";
import clsx from "clsx";

export const BannerDisplay: React.FC = () => {
    const {getNotices} = useAppStateStore();
    let activeNotices = getNotices();
    let banners: Notice[] = activeNotices.filter((notice: Notice) => notice.type === "banner");

    if (banners.length === 0) {
        return null;
    }
    const banner: Notice = banners[0];

    let actions: Array<React.ReactNode> = [];
    if (banner.actions && banner.actions.length > 0) {
        if (!banner.style) {
            banner.style = "default";
        }
        actions = banner.actions.map(
            (action: NoticeAction) => <button
                className={clsx([noticeStyles[banner.style as string].button as string, "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto" ])}
                onClick={()=>{
                    action.func();
                }}
                key={action.name}
            > {action.name} </button>
        )
    }
    return (
        <div className="p-4 font-md font-bold bg-primary-100 w-full flex flex-row justify-between">
            <div>
                {banner.content ? banner.content : banner.message}
            </div>
            <div className='flex flex-row'>
                {actions}
                <button className='rounded-full bg-slate-900 bg-opacity-25 py-1 px-4' onClick={() => {
                    alert("Requires hookup")
                }}>X
                </button>
            </div>
        </div>
    );
}
