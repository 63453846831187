import {create} from "zustand";
import {ExternalUser, ExternalUserLinkage, ExternalUserLinkageAction, User} from "@/types";

type AppLinkage = { [key: string]: ExternalUserLinkage }

export type NewAppLinkageStore = {
    linkages: AppLinkage;
    currentApp: string;
    setLink: (externalUser: ExternalUser, orgUser: User) => void;
    setInvite: (externalUser: ExternalUser, fullname: string, email: string) => void;
    setUnlink: (externalUser: ExternalUser) => void;
    unset: (externalUser: ExternalUser) => void;
    reset: (orgAppUuid: string, externalUsers: Array<ExternalUser>) => void;
};


export const useAppLinkageStore =  create<NewAppLinkageStore>((set: any, get: any) => ({
    linkages: {},
    currentApp: "",
    setLink: (externalUser: ExternalUser, orgUser: User) => {
        const {linkages} = get();
        const appLinkage = linkages[externalUser.id]
        if (!appLinkage) {
            return; // if linkage not found, it wasn't initialized, something is wrong
        }
        if (appLinkage.action === ExternalUserLinkageAction.Unlink) {
            console.log("Can't link user to an account that is currently being unlinked");
            return;
        }

        const updatedLinkage = {
            ...appLinkage,
            action: ExternalUserLinkageAction.Linked,
            linked_user: orgUser,
            invite_email: "",
        };
        set(() => ({linkages: {...linkages, [externalUser.id]: updatedLinkage}}));
    },
    setInvite: (externalUser: ExternalUser, fullname: string, email: string) => {
        const {linkages} = get();
        const appLinkage = linkages[externalUser.id]
        if (!appLinkage) {
            return; // if linkage not found, it wasn't initialized, something is wrong
        }
        if (appLinkage.action === ExternalUserLinkageAction.Unlink) {
            console.log("Can't invite a new user to be linked to an account that is currently being unlinked");
            return;
        }
        const updatedLinkage = {
            ...appLinkage,
            action: ExternalUserLinkageAction.Invite,
            linked_user: null,
            invite: {
                email,
                fullname,
            },
        };
        set(() => ({linkages: {...linkages, [externalUser.id]: updatedLinkage}}));
    },
    setUnlink: (externalUser: ExternalUser) => {
        const {linkages} = get();
        const appLinkage = linkages[externalUser.id]
        if (!appLinkage) {
            return; // if linkage not found, it wasn't initialized, something is wrong
        }
        if (appLinkage.action !== ExternalUserLinkageAction.None) {
            console.log("Can't unlink a user without an existing link to an org user");
            return;
        }
        const updatedLinkage = {
            ...appLinkage,
            action: ExternalUserLinkageAction.Unlink,
            invite_email: "",
        };
        set(() => ({linkages: {...linkages, [externalUser.id]: updatedLinkage}}));
    },
    unset: (externalUser: ExternalUser) => {
        const {linkages} = get();
        const appLinkage = linkages[externalUser.id]
        if (!appLinkage || appLinkage.action === ExternalUserLinkageAction.None) {
            return; // if linkage not found, it wasn't initialized, something is wrong
        }
        const payload: any = {
            action: ExternalUserLinkageAction.None,
        };

        if (appLinkage.action !== ExternalUserLinkageAction.Unlink) {
            payload.linked_user = null;
            payload.invite_email = "";
        }
        const updatedLinkage = {
            ...appLinkage,
            ...payload,
        };
        set(() => ({linkages: {...linkages, [externalUser.id]: updatedLinkage}}));
    },
    reset: (orgAppUuid: string, externalUsers) => {
        const linkages: {[key: string]: ExternalUserLinkage} = {}
        externalUsers.forEach((externalUser: ExternalUser) => {
            linkages[externalUser.id] = {
                action: ExternalUserLinkageAction.None,
                invite_email: "",
                linked_user: externalUser.user || null,
                external_user: externalUser,
            } as ExternalUserLinkage
        })
        set(() => ({linkages: linkages, currentApp: orgAppUuid}));
    },

}));