import React from "react";
import {Page, Text, View, Document, StyleSheet} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        justifyContent: "flex-start",
        backgroundColor: "#E4E4E4",
    },
    section: {
        margin: 10,
        padding: 35,
        flexGrow: 1
    },
    header: {
        fontSize: 26,
        textAlign: "center",
        marginBottom: 20,
        textTransform: "uppercase"
    },
    p: {
        marginBottom: 10,
        fontSize: 16,
    },
    b: {
        fontWeight: "bold",
    },
    headerDetails: {
        fontSize: 24,
    },
    details: {
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
});

type RecoveryDocumentProps = {
    orgKey: string;
    secretKey: string;
}

export const RecoveryDocument: React.FC<RecoveryDocumentProps> = ({ orgKey, secretKey}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.header}>Key Forge Account Recovery Kit</Text>
                    <Text style={styles.p}>This PDF contains the information you need to recover your encrytped data in case you are locked out of your account or if there is an issue preventing your access to your data.</Text>
                    <Text style={styles.p}>The information provided is highly sensitive and you should store it within a protected and durable place. Recovery of this information is not possbile, and to use Key Forge without it may require the removal of your data.</Text>
                </View>
                <View style={{...styles.section, ...styles.details}}>
                    <Text style={{...styles.header, ...styles.headerDetails}}>Recovery Details</Text>
                    <View style={styles.section}>
                        <Text style={{...styles.p, ...styles.b}}>Organization Key:</Text>
                        <Text style={styles.p}>{orgKey}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={{...styles.p, ...styles.b}}>Secret Key:</Text>
                        <Text style={{...styles.p}}>{secretKey}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};