import React, {useEffect} from 'react';
import {useWorker} from '@/hooks';
import {Login} from '@/components/organisms';
import { FeatureFlag, Maillist } from '@/components/organisms';

export const PageLogin: React.FC = () => {
    const [worker] = useWorker();

    useEffect(() => {
        if (!worker) {
            return
        }
        // console.log("This is where I'll generate keys");
        // worker.Crypto.generateRSAKeys().then((cryptoKeys) => {
        //     console.log(cryptoKeys);
        // });
    }, [worker])

    const Waitlist = <div className='bg-white bg-opacity-50 text-primary-900 max-w-prose mx-auto p-8'>
        <h1 className='text-2xl text-center pb-4'>Be notified when we&apos;re available!</h1>
        <p>We are placing the finishing touches on Key Forge and will be launching in the next few weeks. If you&apos;d like us to notify you via email when it is ready, use the button below:</p>
        <Maillist mailingList='clzy84o7i01gq0ljrh0hqgdhd' variant='solid' color='primary'/>
    </div>

    return (
        <FeatureFlag flag='earlyaccess' alternative={Waitlist}>
            <Login />
        </FeatureFlag>
    );
}
