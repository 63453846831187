import React from "react";
import { Code } from "@/types/code";
import {CodeCard} from "@/components/molecules";

type CodeGridProps = {
  codes: Array<Code>
}
export const CodeGrid: React.FC<CodeGridProps> = ({codes}) => {
  const renderCodes = () => {
    if (codes.length === 0) {
      return null; // This component probably shouldn't even get loaded if codes if empty, but we'll catch the edge case here
    }
    return codes.map((code: Code) => <li key={code.uuid}><CodeCard code={code} /></li>)
  }

  return (
    <>
      <ul role="list" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
        {/* <button
          onClick={()=>{ackAlert(1)}}
          className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
          >Ack first alert</button> */}
        { renderCodes() }
      </ul>
    </>
  )
}