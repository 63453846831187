import React from "react";
import {User} from "@/types";
import {UserCircleIcon} from "@heroicons/react/24/solid";

type UserAvatarProps = {
    user: User
}

export const UserAvatar: React.FC<UserAvatarProps> = ({user}) => {
    if (user?.settings?.avatar_data) {
        return (
            <img alt="" src={user.settings.avatar_data} className="h-11 w-11 rounded-full"/>
        )
    }
    if (user?.settings?.avatar_data) {
        return (
            <img alt="" src={user.settings.avatar_data} className="h-11 w-11 rounded-full"/>
        )
    }
    return (
        <div className="h-11 w-11 rounded-full bg-gray-200">
            <UserCircleIcon className="h-11 w-11 text-gray-300"/>
        </div>
    )
}