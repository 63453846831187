import {OrganizationApp} from "@/types/organization.ts";

export enum GroupType {
    Private = "private",
    Shared = "shared",
    ServerShared = "server_shared",
}

export enum GroupStatus {
    Active = "active",
    Inactive = "inactive",
    Suspended = "suspended",
}

export type Group = {
    uuid: string;
    label: string;
    description: string,
    type: GroupType;
    organization_uuid: string;
    settings: GroupSettings;
    credentials: string;
    status: GroupStatus;
    group_users: Array<GroupUser>;
    user_count: number;
    app_count: number;
    apps: Array<GroupApp>;
}

export const getDefaultGroup = (): Group => {
    return {
        uuid: "",
        label: "",
        description: "",
        type: GroupType.Shared,
        organization_uuid: "",
        settings: {} as GroupSettings,
        credentials: "",
        status: GroupStatus.Active,
        group_users: [],
        user_count: 0,
        app_count: 0,
        apps: [],
    };
};

export type GroupSettings = {
    max_codes: number;
    icon: string;
}

export const UserGroupRoles = {
    None: 0,
    Admin: 1,
}

export type GroupUser = {
    uuid: string;
    group_uuid: string;
    user_uuid: string;
    roles: number;
    active: boolean;
    expires_at: string | null;
    credentials: string;
}

export type GroupCreds = {
    keys: { [key: string]: string };
    current_key: string;
}

export const newGroupCreds = (): GroupCreds => {
    return {
        keys: {},
        current_key: "",
    };
}

export type GroupApp = {
    uuid: string;
    organization_app_uuid: string;
    group_uuid: string;
    roles: number
    credentials: string;
    org_app: OrganizationApp;
}