import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {UrlOrganizationGroups} from "@/constants"
import {CreateGroup} from "@/components/organisms";

type PageCreateOrganizationGroupProps = {}

export const PageCreateOrganizationGroup: React.FC<PageCreateOrganizationGroupProps> = () => {
    const {orgKey} = useParams();
    const navigate = useNavigate()

    const handleCreate = () => {
        navigate(UrlOrganizationGroups(orgKey!));
    }

    return (
        <CreateGroup orgKey={orgKey!} onCreate={handleCreate} />
    );
}
