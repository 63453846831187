import React, { useState, useEffect } from 'react';
import { Code } from '@/types';
import { CodeGrid } from "@/components/molecules";
import { MagnifyingGlassIcon, BarsArrowUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type CodeNavigatorProps = {
  codes: Array<Code>
}

type MatchField = {
    location: string,
    weight: number
}

// Utility function to safely access nested properties
const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc: any, part: string) => acc && acc[part], obj);
};

const calculateMatchScore = (item: Code, query: string) => {
  const fields: {[key: string]: MatchField} = {
    label: {
      location: "label",
      weight: 6
    },
    alias: {
      location: "alias",
      weight: 3
    },
    group: {
      location: "group.label",
      weight: 1
    },
    organization: {
      location: "organization.label",
      weight: 1
    },
  };
  const queryWords = query.toLowerCase().split(" ").filter(i => i);
  if (queryWords.length === 0) {
    return 11; // If there isn't an active search, give all codes the top score
  }
  let score = 0;
  queryWords.forEach((word)=>{
    let wordScore = 0;
    const fieldKeys = Object.keys(fields);
    fieldKeys.forEach((field: string)=>{
      const value = fields[field];
      const fieldValue = getNestedValue(item, value.location);
      if (fieldValue && fieldValue.toLowerCase().includes(word)) {
        wordScore = wordScore + value.weight;
      }
    })
    score += wordScore;
  });
  return score;
};

export const CodeNavigator: React.FC<CodeNavigatorProps> = ({codes}) => {
  const [codeList, setCodeList] = useState<Array<Code>>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [advToggle, setAdvToggle] = useState(false);

  useEffect(() => {
    const query = searchQuery.toLowerCase();

    const sortedCodes = codes.map((code: Code) => {
      // Calculate Match Score, weigh labels higher than orgs and groups.
      // const matchScore = 
      //   (code.label.toLowerCase().includes(query) ? 3 : 0) +
      //   (code.alias.toLowerCase().includes(query) ? 2 : 0) + 
      //   (code.group?.label?.toLowerCase().includes(query) ? 1 : 0) +
      //   (code.organization?.label?.toLowerCase().includes(query) ? 1 : 0);

      const matchScore = calculateMatchScore(code, query);

      return {
        ...code,
        matchScore,
        match: matchScore > 0
      };
    }).sort((a, b) => b.matchScore - a.matchScore);

    setCodeList(sortedCodes);
  }, [searchQuery, codes]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const matchCodes = (match: boolean) => { return codeList.filter(code => code.match == match)};

  return (
    <div className="">
      <div className="mt-2 flex rounded-md shadow-sm mx-auto max-w-5xl">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </div>
          <input
            id="codeNavigator"
            name="codeNavigator"
            type="search"
            placeholder="Search by Name, Group, Org"
            autoFocus
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={searchQuery}
            onChange={handleSearchChange} 
          />
        </div>
        <button
          type="button"
          className={clsx(
            "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 bg-white",
            advToggle && "bg-slate-200"
          )}
          onClick={()=>{setAdvToggle(!advToggle);}}
        >
          <BarsArrowUpIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-gray-400" />
          Adv.
        </button>
      </div>
      <div className="p-2 transition-all duration-1000">
        {advToggle && 
            <div className='bg-primary-50 dark:bg-primary-900 text-primary-900 dark:text-primary-100 p-4 rounded-lg'>
                <div>
                  <label htmlFor="codeGroupBy" className="block text-sm font-medium leading-6">
                    Group By
                  </label>
                  <select
                    id="codeGroupBy"
                    name="codeGroupBy"
                    defaultValue="match"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="match">Matching</option>
                    <option value="org">Organization</option>
                    <option value="group">Group</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="codeSortBy" className="block text-sm font-medium leading-6">
                    Sort By
                  </label>
                  <select
                    id="codeSortBy"
                    name="codeSortBy"
                    defaultValue="chron_rev"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="match">Best Match</option>
                    <option value="chron_rev">Oldest</option>
                    <option value="chron">Newest</option>
                    <option value="alpha">Alphabetical</option>
                  </select>
                </div>
                

            </div>
        }
      </div>
      {matchCodes(false).length > 0 || searchQuery !== "" ? 
        <div>
          <h1 className="text-indigo-400 font-bold py-2">Matching</h1>
          {matchCodes(true).length == 0 && <div className="text-center border-dotted border-slate-400 border-1">No matches found</div>}
          <CodeGrid codes={ matchCodes(true) } />
          <h1 className="text-slate-400 font-bold py-2">Not Matching</h1>
          <CodeGrid codes={matchCodes(false)} />  
      </div> : <CodeGrid codes={ matchCodes(true) } />}
      {/* <CodeList codes={codes} /> <-- TODO: Implement list view for codes to aid navigating heirarchy */}
    </div>
  )
}

// NEW LOGIC
// VIEW 1: Grid
//  Show as cards
//  Group by: Match (default), Group, Organization
//  Search rearranges by sorted group
// VIEW 2: List
//  Show as lines
//  Nest in Orgs, then groups
//  Search rearranges list order, where the more matches there is the higher the code ranks
