import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useHttpPost, useTranslation} from "@/hooks";
import {EndpointAuthVerifyEmail} from "@/constants";

export const PageVerifyEmail: React.FC = () => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const {code} = useParams();
    const {execute: verifyEmail} = useHttpPost("");
    const [userUuid, setUserUuid] = useState("");
    const {t} = useTranslation();

    useEffect(() => {
        if (search.indexOf("?") !== -1) {
            search.slice(1).split("&").forEach((param) => {
                if (param.startsWith("u=")) {
                    setUserUuid(param.slice(2));
                }
            });
        }
    }, [search]);

    useEffect(() => {
        if (userUuid === "" || code === "") {
            return;
        }
        verifyEmail(EndpointAuthVerifyEmail(), {
            user_uuid: userUuid,
            code: code
        }, {}).then(() => {
            navigate("/", { replace: true });
        })
    }, [userUuid, code])

    return (
        <div className='bg-primary-25 text-primary-900 rounded-lg shadow-lg dark:bg-primary-500 dark:text-primary-50 p-6 mx-auto max-w-2xl'>
            {t("Completing email verification process")}
        </div>
    );
}
