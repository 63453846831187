'use client'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Button } from '../atoms'

const baseStyles = {
  solid:
    'group shadow-md inline-flex items-center justify-center rounded-md my-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex ring-1 items-center justify-center rounded-md my-4 text-sm focus:outline-none ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
}

const variantStyles = {
  solid: {
    slate:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    blue: 'bg-kfdarker text-white hover:text-slate-100 hover:bg-kfdarkest active:bg-blue-800 active:text-blue-100 focus-visible:bg-kfdarkest',
    white:
      'bg-white text-slate-900 hover:bg-white active:bg-white focus-visible:outline-white',
    primary: 'transition duration-300 ease-in bg-primary-700 text-white hover:text-slate-100 hover:bg-primary-900 active:bg-primary-900 active:text-primary-100 focus-visible:bg-primary-900',
    secondary: 'transition duration-300 ease-in bg-secondary-700 text-white hover:bg-secondary-500 active:bg-secondary-100 active:text-slate-600 focus-visible:outline-white',
  },
  outline: {
    slate:
      'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
    white:
      'bg-transparent',
    primary: 'ring-kfdark text-kfdarker hover:text-kfdarkest hover:ring-kfdark active:kfdark active:kfdarker focus-visible:outline-kfdarker focus-visible:ring-kfdark',
    secondary: 'ring-kflight text-white hover:ring-kflighter active:ring-kflight active:ring-kflightest focus-visible:outline-white',
  },
}

type MaillistProps = {
  mailingList: string;
  productName?: string;
  action?: string;
  variant?: 'solid' | 'outline';
  color?: keyof typeof variantStyles.solid | keyof typeof variantStyles.outline;
};

export function Maillist({ ...props }: MaillistProps) {
  const [ active, setActive ] = useState(false);
  const [ consent, setConsent ] = useState(false);
  const [ response, setResponse] = useState<null | "success" | "error">(null);

  const toggleActive = (bool: boolean) => {
    setActive(bool);
  }

  const sendMaillistReq = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const mailingList = encodeURIComponent(props.mailingList);
    const email = encodeURIComponent((form.elements.namedItem('email') as HTMLInputElement).value);
    const consent = (form.elements.namedItem('consent') as HTMLInputElement).checked;
    if (consent) {
      fetch(`https://app.loops.so/api/newsletter-form/clraxg5oy014v5ahwxfv0besg`, {
        method: "POST",
        body: `email=${email}&mailingLists=${mailingList}&source=website`, //mailingLists= userGroup=
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((res: any) => {
        res.ok ? setResponse("success") : setResponse("error");
      }).catch(()=>{
        setResponse("error");
      })
    }    
  }

  const consentAction = () => {
    setConsent(!consent);
  }

  props.productName ??= 'the product'
  props.variant ??= 'outline'

  return (
    <>
      <div className={clsx('transition-all duration-500 ease-in hover:cursor-pointer overflow-hidden', props.variant === 'outline' ? baseStyles.outline : baseStyles.solid, props.variant === 'outline' ? variantStyles.outline.white : variantStyles.solid.white, active && 'h-auto hover:border-primary-100', active && props.variant === 'solid' && 'hover:bg-white')}>
        {active ? <form className={clsx('py-4 px-6 pt-6', props.variant === 'solid' ? 'text-primary-900' : 'text-primary-50')} onSubmit={sendMaillistReq}>
          <input 
            autoFocus
            required
            name="email"
            type='email' 
            placeholder='Enter your email' 
            className='bg-transparent border-0 border-b-2 mb-4 border-primary-300 focus:border-primary-50 placeholder:italic placeholder:text-primary-100' 
          />
          <div className='text-sm mb-4'>
            Submit your email to subscribe to our Product Waitlist email newsletter to know when {props.productName} and other features are launched.
          </div>
          {!response ? 
            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex items-center">
                  <input
                    id="consent"
                    name="consent"
                    type="checkbox"
                    aria-describedby="consent-description"
                    checked={consent}
                    onChange={consentAction}
                    required
                    className={clsx('h-4 w-4 rounded mt-1 text-indigo-600 focus:ring-indigo-600', props.variant === 'outline' ? 'border-primary-100' : 'border-primary-500')}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="consent" className="text-justify text-sm">
                    <b className='text-primary-100'>I consent</b> to the <Link to="https://www.keyforge.io/privacy" target='new' className='underline'>privacy policy</Link> & to receive email communications.
                  </label>
                </div>
              </div>
              <div>
                { consent && 
                  <Button color='white' type='submit'>Submit</Button>
                }
                <Button color='secondary' onClick={()=>{setActive(false)}}>Cancel</Button>
              </div>
            </div>
          :
            <div className='flex flex-row justify-between'>
              <p className='mb-4'>
                  {response == 'success' ? "Success. You'll be notified of our product releases. Thank you and talk soon!"
                  : "There was an error signing you up. You were not subscribed, please refresh and try again."}
              </p>
              <Button color='white' className='justify-end' onClick={()=>(toggleActive(false))}>Close</Button>
            </div>
          }
        </form>:<div className="w-full h-full text-center p-2" onClick={()=>(toggleActive(true))}>{props.action ? props.action : "Join the List"}</div>}
      </div>
    </>
  )
}