import {useEffect, useState} from "react";
import * as Comlink from "comlink"
import {WorkerCallbacks} from "@/types";
import {useAuthRequestStore} from "@/stores/authRequest.ts";

let worker: any = null;
let workerReady: null | Promise<void> = null;
let workerInstance = null;

export const useWorker = (): [(Comlink.RemoteObject<any> | null), () => void] => {
    const [hookInstance, setHookInstance] = useState<any>(null); // use a local hook state so we can re-render on update
    useEffect(() => {
        // if worker already instantiated, nothing to do
        if (!workerReady) {
            workerReady = new Promise<void>(async (resolve, reject): Promise<void> => {
                worker = new Worker(new URL('../workers/cryptoWorker.worker.ts', import.meta.url), {type: "module"})
                const workerClass = Comlink.wrap(worker);

                try {
                    // create comlink worker class instance
                    // @ts-ignore
                    workerInstance = await new workerClass();
                    await workerInstance.RegisterCallback(WorkerCallbacks.OrgAuthRequest, Comlink.proxy((orgKey: string): Promise<boolean> => {
                        return new Promise<boolean>((resolve, reject) => {
                            // @ts-ignore
                            useAuthRequestStore.getState().setRequest({
                                orgKey: orgKey,
                                resolve: resolve,
                                reject: reject
                            });
                        })
                    }));
                    resolve(workerInstance);
                } catch (e) {
                    console.error("Error initializing worker", e);
                    reject(e);
                }
            })
        }
        workerReady.then((instance) => {
            setHookInstance(() => instance);
        });
    }, []);

    const terminate = () => {
        // first caller will terminate the worker, subsequent will get skipped
        if (worker) {
            console.log("Teardown Worker");
            worker[Comlink.releaseProxy]();
            worker.terminate();
            worker = null;
            workerReady = null;
            workerInstance = null;
        }
    }

    return [hookInstance, terminate];
};
