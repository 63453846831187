import React from "react";
import {useTranslation} from "@/hooks";
import {Organization, User, UserStatus} from "@/types";
import {UserRoles, UrlOrganizationUserUpdate} from "@/constants";
import {Link, UserAvatar} from "@/components/atoms";

type UserListProps = {
    organization: Organization
    users: Array<User>
}

export const UserList: React.FC<UserListProps> = ({organization, users}) => {
    const {t} = useTranslation();

    const statusMap = {
        [UserStatus.Active]: t("Active"),
        [UserStatus.Unverified]: t("Unverified"),
        [UserStatus.Pending]: t("Pending"),
        [UserStatus.Recovery]: t("Recovery"),
        [UserStatus.Suspended]: t("Suspended"),
        [UserStatus.Archived]: t("Archived"),
    }

    const renderUsers = () => {
        return users.map((user: User) => {
            return (
                <tr key={user.uuid}>
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0">
                                <UserAvatar user={user}/>
                            </div>
                            <div className="ml-4">
                                <div className="font-medium">{user.fullname}</div>
                                <div className="mt-1">{user.nickname}</div>
                            </div>
                        </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm">
                        <div className="">{user.email}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">
                            {statusMap[user.status]}
                        </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm">{user.roles === UserRoles.Admin ? t("Admin") : t("User")}</td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <Link to={UrlOrganizationUserUpdate(organization.key, user.uuid)} className="text-white hover:text-gray-100">
                            Edit<span className="sr-only">, {user.fullname}</span>
                        </Link>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr className="text-white">
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">
                                Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                Email
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                Status
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold">
                                Role
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {renderUsers()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}