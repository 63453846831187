import React, {useState} from "react";
import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import {ChevronRightIcon, MagnifyingGlassIcon, PencilSquareIcon, UsersIcon} from "@heroicons/react/24/solid";
// import clsx from "clsx";
import {useTranslation} from "@/hooks";
import {useAppLinkageStore} from "@/stores/appLinkage.ts";
import {ExternalUser, ExternalUserLinkageAction, User} from "@/types";
import {Button, Input} from "@/components/atoms";

type AppLinkagePopoverProps = {
    externalUser: ExternalUser;
    orgUsers: Array<User>;
}

export const AppLinkagePopover: React.FC<AppLinkagePopoverProps> = ({externalUser, orgUsers}) => {
    const {t} = useTranslation();
    const [query, setQuery] = useState('')
    const [showInviteForm, setShowInviteForm] = useState(false)
    const [inviteEmail, setInviteEmail] = useState("")
    const [inviteName, setInviteName] = useState("")
    const {linkages, setLink, setInvite, setUnlink} = useAppLinkageStore();

    const filteredList = orgUsers.filter((orgUser) => {
        // if query is not empty and user's fullname or email doesn't include the query, don't show them in the list
        if (query !== "" && !orgUser.fullname.toLowerCase().includes(query.toLowerCase()) && !orgUser.email.toLowerCase().includes(query.toLowerCase())) {
            return false
        }
        // if user is already linked to an external user, don't show them in the list
        if (linkages[orgUser.uuid]?.linked_user?.uuid) {
            return false;
        }
        return true;
    })


    /*
     {
      "uuid": "7758beff-147f-4254-8d3b-9407b2900f19",
      "account_id": "CNDXBR",
      "fullname": "Test 3",
      "nickname": "Test Nick",
      "email": "cj+3@wumu.io",
      "roles": 0,
      "status": "active",
      "settings": {
        "timezone": "",
        "language": "",
        "avatar_data": "",
        "avatar_url": ""
      },
      "public_key": "",
      "created_at": "2024-08-20T23:45:57.462171-04:00",
      "updated_at": "2024-09-02T00:04:18.207039-04:00"
    },
     */

    const statusLabel = () => {
        if (!linkages) {
            return;
        }
        const linkage = linkages[externalUser.id];
        if (linkage.action === ExternalUserLinkageAction.None) {
            // nothing has been done yet, determine if it should be linked or unlinked
            return linkage.linked_user ? t("Linked") : t("Unlinked")
        }

        let label;
        switch (linkage.action) {
            case ExternalUserLinkageAction.Invite:
                label = t("Inviting User");
                break;
            case ExternalUserLinkageAction.Linked:
                label = t("Linking to User");
                break;
            case ExternalUserLinkageAction.Unlink:
                label = t("Unlinking User");
                break;
            // nothing has been done yet, determine if it should be linked or unlinked
            default:
                label = linkage.linked_user ? t("Linked") : t("Unlinked")
        }
        return (
            <div className="flex">
                <div className="flex-shrink-0">
                    <PencilSquareIcon aria-hidden="true" className="h-5 w-5 text-yellow-500"/>
                </div>
                <div className="ml-3">
                    <p className="text-sm">
                        {label}
                    </p>
                </div>
            </div>
        )
    }

    const renderLinkableList = (close: any) => {
        const linkage = linkages[externalUser.id];
        // if show invite form is true or the user is already linked, don't show the list
        if (showInviteForm) {
            return null;
        }

        if (linkage?.linked_user) {
            return (
                <>
                    <div className="text-sm px-4 py-2 bg-gray-200">{t("Linked User")}</div>
                    <div className="flex min-w-0 gap-x-4 p-4">
                        {linkage.linked_user?.settings?.avatar_url && <img alt="" src={linkage.linked_user?.settings?.avatar_url} className="h-12 w-12 flex-none rounded-full bg-gray-50"/>}
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                {linkage.linked_user?.fullname}
                            </p>
                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                {linkage.linked_user?.nickname}
                            </p>
                        </div>
                    </div>
                    <Button className="inline-block cursor-pointer flex items-center m-4" type="button" onClick={() => {
                        setUnlink(externalUser);
                        close();
                    }}>{t("Unlink")}</Button>
                </>
            )
        }

        if (query !== '' && filteredList.length === 0) {
            return (
                <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                    <p className="mt-4 font-semibold text-gray-900">No users found</p>
                    <p className="mt-2 text-gray-500">Are you sure the user you are looking for is active?</p>
                </div>
            )
        }

        return (query === '' || filteredList.length > 0) && (
            <>
                <div className="text-sm px-4 pt-2">{t("Select existing user:")}</div>
                <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                <div className="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2 mx-2 my-2  border rounded-lg border-gray-100">
                        <div className="-mx-2 text-sm text-gray-700">
                            {filteredList.map((orgUser) => {
                                    return (
                                        <ComboboxOption
                                            as="div"
                                            key={orgUser.uuid}
                                            value={orgUser}
                                            className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            {orgUser?.settings?.avatar_url && <img src={orgUser?.settings?.avatar_url} alt="" className="h-6 w-6 flex-none rounded-full"/>}
                                            <span className="ml-3 flex-auto truncate">{orgUser.fullname}</span>
                                            <ChevronRightIcon
                                                className="ml-3 hidden h-5 w-5 flex-none text-gray-400 group-data-[focus]:block"
                                                aria-hidden="true"
                                            />
                                        </ComboboxOption>
                                    )
                                }
                            )}
                        </div>
                    </div>
                </ComboboxOptions>
            </>

        )
    }

    const renderInviteForm = (close: any) => {
        if (!showInviteForm) {
            return null;
        }
        {/*<input*/}
        {/*    id="desktop-search-candidate"*/}
        {/*    name="desktop-search-candidate"*/}
        {/*    type="text"*/}
        {/*    placeholder="Search candidates"*/}
        {/*    className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"*/}
        {/*/>*/}

        {/*<button*/}
        {/*    type="button"*/}
        {/*    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
        {/*>*/}
        return (
            <>
                <div className="text-sm px-4 pt-2">{t("Invite new user:")}</div>
                <div className="px-4 py-2">
                    <div className="relative">
                        <label htmlFor="invite_name">{t("Name")}</label>
                        <Input id="invite_name" name="invite_name" type="text"
                               value={inviteName}
                               onChange={(e: any) => {
                                   setInviteName(e.target.value)
                               }}/>
                    </div>
                    <div className="relative mt-2">
                        <label htmlFor="invite_email">{t("Email")}</label>
                        <Input id="invite_email" name="invite_email" type="email"
                               value={inviteEmail}
                               onChange={(e: any) => {
                                   setInviteEmail(e.target.value)
                               }}/>
                    </div>
                    <Button outline={true}
                            className="inline-block cursor-pointer mt-2"
                            type="button" onClick={() => {
                        setInvite(externalUser, inviteName, inviteEmail);
                        setShowInviteForm(false);
                        close();
                    }}>{t("Save")}</Button>
                </div>
            </>
        )
    }

    if (linkages[externalUser.id]?.action === ExternalUserLinkageAction.Unlink) {
        return (<span className="cursor-default">{statusLabel()}</span>)
    }

    return (
        <Popover className="relative bg-white">
            <PopoverButton>{statusLabel()}</PopoverButton>
            <PopoverPanel anchor="bottom"
                          className="absolute bg-white min-w-96 divide-gray-100 overflow-hidden rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 transition-opacity data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in">
                {({ close }) => (
                    <Combobox
                        onChange={(user) => {
                            setLink(externalUser, user as User);
                            close();
                        }}
                    >
                        {!externalUser?.user && <div className="relative">
                            <MagnifyingGlassIcon
                                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            <ComboboxInput
                                autoFocus
                                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                placeholder="Search..."
                                onChange={(event) => setQuery(event.target.value)}
                                onBlur={() => setQuery('')}
                            />
                        </div>}
                        {renderLinkableList(close)}
                        {renderInviteForm(close)}
                        {!externalUser?.user && <div className="flex transform-gpu border-t border-gray-500 bg-gray-100 mt-2">
                            <div className="max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-4 py-2">
                                <span className="text-sm">or </span>
                                <Button outline={true} className="inline-block cursor-pointer flex items-center ml-1" type="button" onClick={() => {
                                    setShowInviteForm(!showInviteForm) ;
                                    setInviteEmail(externalUser.email);
                                    setInviteName(externalUser.fullname);
                                }}>{showInviteForm ? t("Link Existing User") : t("Invite User")}</Button>
                            </div>
                        </div>}
                    </Combobox>
                )}
            </PopoverPanel>
        </Popover>
    )
}