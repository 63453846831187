import React from "react";
import { Link } from "@/components/atoms";
import lightLogo from "@/assets/logo/key_forge_light.png"
import darkLogo from "@/assets/logo/key_forge_light.png"
import clsx from "clsx";

type LogoProps = {
    href?: string,
    className?: string,
}

const DefaultLogoClasses = "h-16 mx-auto";

export const Logo: React.FC<LogoProps> = ({href = "/", className = ""}) => {
    return (
        <Link to={href} className={className}>
            <img
                className={clsx(DefaultLogoClasses, "dark:hidden" )}
                src={lightLogo as string}
                alt="Key Forge"
            />
            <img
                className={clsx(DefaultLogoClasses, "hidden dark:block" )}
                src={darkLogo as string}
                alt="Key Forge"
            />
        </Link>
    )
}