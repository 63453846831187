import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useHttpPost, useWorker} from "@/hooks";
import {useAppStateStore} from "@/stores/keyforgeState"
import {EndpointAuthLogin, UrlOrganizationCreate} from "@/constants";
import {Button, Input, Link} from "@/components/atoms";
import {AuthCreds} from "@/types";

type LoginProps = {}

export const Login: React.FC<LoginProps> = ({}) => {
    const [worker] = useWorker();
    const {execute: login} = useHttpPost();
    const [orgKey, setOrgKey] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const {addOrg, addNotice} = useAppStateStore();
    const navigate = useNavigate()

    useEffect(() => {
        setOrgKey("keyforge");
        setEmail("test@keyforge.io")
        setPassword("qwerty123");
        setSecretKey("TEMP-FAKE-KEY")
    }, [])

    const loginHandler = async (e: any) => {
        e.preventDefault();
        if (!worker) {
            // TODO: Add error handling
            return
        }

        try {
            console.log("Logging In...")
            const hashedPassword = await worker.HashPassword(password);
            const response = await login(EndpointAuthLogin(), {
                org_key: orgKey,
                identity: email,
                password: hashedPassword,
                with_creds: true,
            }, {})
            const creds = response.creds;

            addOrg(response.organization, response.user, {access_token: response.access_token, expiration: response.expiration} as AuthCreds)

            await worker.Login({
                orgKey: orgKey,
                secretKey: secretKey.replace(/[-]/g, ""),
                email: email,
                password: password,
                salt: creds.salt,
                iv: creds.iv,
                encodedPublicKey: creds.public_key,
                encryptedPrivateKey: creds.private_key,
            });

            // the user has logged in, add to keyforge state, worker, and redirect to org page
            navigate(`/organizations/${response.organization.key}`);
        } catch (e: any) {
            addNotice({
                type:"notify",
                style:"error",
                message: e.message || "Cannot log into this account at this time."
            })
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mx-auto max-w-2xl  border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="h-16 w-auto mx-auto dark:hidden"
                    src="assets/logo/key_forge_light.png"
                    alt="Key Forge"
                />
                <img
                    className="h-16 w-auto mx-auto hidden dark:block"
                    src="assets/logo/key_forge_dark.png"
                    alt="Key Forge"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={loginHandler} method="POST">
                    <div>
                        <label htmlFor="orgKey" className="block text-sm font-medium leading-6">
                            Org Key
                        </label>
                        <div className="mt-2">
                            <Input id="orgKey" name="orgKey" type="text" required value={orgKey} onChange={(e) => setOrgKey(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                            Email
                        </label>
                        <div className="mt-2">
                            <Input id="email" name="email" type="email" autoComplete="email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6">
                            Password</label>
                        <div className="mt-2">
                            <Input id="password" name="password" type="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="secret-key" className="block text-sm font-medium leading-6">
                            Secret Key</label>
                        <div className="mt-2">
                            <Input id="secret-key" name="secret-key" type="input" value={secretKey} onChange={(e) => setSecretKey(e.target.value)} required/>
                        </div>
                    </div>

                    <div>
                        <Button type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            Login
                        </Button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm">
                    Looking to create a new account?&nbsp;
                    <Link to={UrlOrganizationCreate()} className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300">Register here.</Link>
                </p>
            </div>
        </div>
    );
};
