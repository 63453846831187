import React from "react";

type LoadingCircleProps = {
    percent: number,
    style: "thin" | "thick",
    color: string,
    stroke: number,
}
export const LoadingCircle: React.FC<LoadingCircleProps> = ({percent, color = "blue", stroke = 2}) => {
    const fill = (per: number) => {
      return 100-per;
    };
    const dynamicStroke = (s: number) => {
      let conversion = {
        c: 18,
        vB: "0 0 36 36",
        sW: 2
      }
      if (s != 2) {
        conversion.sW = s; // Set Stroke Width to prop
        conversion.c = 16+(s); // Calculate circle X/Y Coordinates
        conversion.vB = `0 0 ${conversion.c*2} ${conversion.c*2}`; // Calcualte view box by new circle coordinates
      }
      return conversion;
    };
    const tailwindColor = (c: string) => {
      if (c === "white") {
        return `stroke-current text-white dark:text-white`
      }
      return `stroke-current text-${c}-600 dark:text-${c}-500`
    };
    return (
      <svg className="full-width -rotate-90 mb-1" viewBox={dynamicStroke(stroke).vB} xmlns="http://www.w3.org/2000/svg">
        <circle cx={dynamicStroke(stroke).c} cy={dynamicStroke(stroke).c} r="15.9155" fill="none" className={tailwindColor(color)} strokeWidth={dynamicStroke(stroke).sW} strokeDasharray="100" strokeDashoffset={fill(percent)} strokeLinecap="butt"></circle>
      </svg>
    )
}