import clsx from "clsx";
import { SubscriptionPlan, SubscriptionPeriod } from "@/constants";
import {Button} from "@/components/atoms/Button";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {useTranslation} from "@/hooks";

type SubscriptionTileProps = {
    plan: SubscriptionPlan,
    disabled?: boolean,
    checkoutFunc: Function,
    billingPeriod: SubscriptionPeriod,
    subQuantity: number,
    orgUuid: string
}

const totalCostCalculation = (
    baseCost: number[], 
    unitCost: number[], 
    units: number, 
    seatsIncluded: number, 
    costIndex: number
) => {
    const costCalc = units > seatsIncluded && unitCost.length > 0 ? (baseCost[costIndex] + ((units - seatsIncluded) * unitCost[costIndex])) : baseCost[costIndex];
    return costCalc;
};

const billingPeriodIndex = (period: SubscriptionPeriod) => {
    if (period === SubscriptionPeriod.Annual) {
        return 1
    } else {
        return 0
    }
}

const money = (pennies: number) => {
    return `$${pennies/100}`
}

export const SubscriptionTile: React.FC<SubscriptionTileProps> = ({plan, disabled = false, checkoutFunc, billingPeriod, subQuantity, orgUuid}) => {
    const {t} = useTranslation();
    return (
        <div className={clsx(
            "text-primary-900 dark:text-primary-100 border border-primary-300 flex flex-col gap-2 rounded-3xl px-6 sm:px-8 py-6",
            {["opacity-20"]: disabled})}>
            <h1 className="mt-5 font-display text-md font-bold">
                {plan.label}
            </h1>
            <p>
                {plan.maxQuantity > 1 ? t("Starting at") : t("Simply")}
            </p>
            <p className="font-display text-5xl font-light tracking-tight text-primary-500">
                {plan.billingPeriods.indexOf(billingPeriod) >= 0 && plan.maxQuantity >= subQuantity ?
                    <>{money(plan.baseCost[billingPeriodIndex(billingPeriod)])}
                    <span className="text-[.55em] relative bottom-2"> /{billingPeriod == SubscriptionPeriod.Monthly ? t("month") : t("year")}</span></>
                : 
                    <>{t("Unavailable")}</>
                }
            </p>
            <p className="font-display text-lg font-light tracking-tight">
                {plan.desc}
            </p>
            <ul className="py-4">
                {plan.benefits.map((benefit)=><li key={benefit}>
                   <CheckCircleIcon className="w-4 h-4 inline" /> {benefit}
                </li>)}
            </ul>
            <ul className="flex flex-col gap-2 my-2 p-2">
                <li className="border-b border-dotted border-primary-500">Base Price 
                    <span className="inline float-right">
                        {money(plan.baseCost[billingPeriodIndex(billingPeriod)])}
                    </span>
                </li>
                <li className="text-sm relative bottom-1">({plan.includedUnits} Seat{plan.includedUnits > 1 ? "s" : ""} included)</li>
                {(plan.unitCost && subQuantity > plan.includedUnits) && <li className="border-b border-dotted border-primary-500">
                    Additional Seat Cost: 
                    <span className="inline float-right">
                        {money(plan.unitCost[billingPeriodIndex(billingPeriod)] * (Math.max(subQuantity-plan.includedUnits, 0)))}
                    </span>
                </li>}
                {(plan.unitCost && subQuantity > plan.includedUnits) && <li className="text-sm relative bottom-1">
                     {Math.max(subQuantity-plan.includedUnits, 0)} added seats at {money(plan.unitCost[billingPeriodIndex(billingPeriod)])}/seat
                </li>}
                <li className="border-t-4 border-double border-primary-500 text-right pt-2">Total: {money(totalCostCalculation(plan.baseCost, plan.unitCost || [], subQuantity, plan.includedUnits, billingPeriodIndex(billingPeriod)))}</li>
            </ul>
            {!disabled && 
                <Button onClick={()=>checkoutFunc(orgUuid, `${plan.id}_${billingPeriod}` , subQuantity)} color="secondary"
                    type="button"
                    className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold"
                >
                    Subscribe for {money(totalCostCalculation(plan.baseCost, plan.unitCost || [], subQuantity, plan.includedUnits, billingPeriodIndex(billingPeriod)))}
                </Button>
            }
        </div>
    );
}