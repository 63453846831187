import React from "react";
import { Switch } from '@headlessui/react';
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import clsx from "clsx";


type PagePreferencesProps = {}

export const PagePreferences: React.FC<PagePreferencesProps> = () => {
    const {getDarkMode, setDarkMode} = useAppStateStore();

    const darkModeStatus = () => {
        let current = getDarkMode();
        if (current == "system") {
            return document.documentElement.classList.contains("dark")
        } else if (current == "dark") {
            return true;
        } else {
            return false;
        }
    };

    const toggleDarkMode = () => {
        let current = getDarkMode();
        if (current == "dark") {
            setDarkMode("light");
        } else if (current == "light") {
            setDarkMode("dark");
        }
    };

    const toggleDarkSystem = () => {
        if (getDarkMode() === "system") {
            setDarkMode("light");
        } else {
            setDarkMode("system");
        }
    };

    return (
        <div className='mx-auto max-w-5xl p-6 border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500'>
            <div>
                This is the preferences for this app, and changes will only affect this app.
            </div>
            <div className="py-6">
                <h2 className="font-bold text-2xl pb-2">Enable Dark Mode</h2>
                <p>Change the style for this application.</p>
                <div className={clsx("grid grid-cols-3 gap-2 w-36 justify-center", getDarkMode()==="system" && " text-slate-500")}>
                    <div className="">Light</div>
                    <div>
                        <Switch
                            checked={darkModeStatus()}
                            disabled={getDarkMode()==="system"}
                            onChange={toggleDarkMode}
                            className={clsx("group relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600", getDarkMode()!=="system" && "cursor-pointer")}
                            >
                            <span className="sr-only">Enable Dark Mode</span>
                            <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                            />
                        </Switch>
                    </div>
                    <div>Dark</div>
                </div>
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                        id="system_dark_mode"
                        name="system_dark_mode"
                        type="checkbox"
                        aria-describedby="system_dark_mode_description"
                        checked={getDarkMode()==="system"}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={toggleDarkSystem}
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label htmlFor="system_dark_mode" className="font-medium text-slate-300">
                        Use System Settings
                        </label>
                        <p id="system_dark_mode_n" className="text-slate-400">Sync this application's dark mode settings to your computer's preferences.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
