import React from "react";
import { Billboard } from "@/components/atoms/Billboard";

export const Suspended: React.FC = () => {
    
    return (
        <div>
            <Billboard
                style='warn'
                header='Your Account Is Suspended'
                desc='An Administrator has place your account in Suspension. While your data is still protected, you are unable to access it unless an administrator reactivates your account. If you believe this is in error, please contact your Organization&apos;s Administrator.'
            />
        </div>
    );
}
