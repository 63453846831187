import {create} from "zustand";
import {Code} from "@/types";

const getDefaultNewCode = (): Code => {
    return {
        group_uuid: "",
        label: "",
        alias: "",
        issuer: "",
        account: "",
    } as Code;
}

export enum NewCodeStep {
    Upload = "upload",
    Config = "config",
}

export type NewCodeStore = {
    code: Code;
    step: NewCodeStep;
    setOrganizationUuid: (organizationUuid: string) => void;
    setGroupUuid: (groupUuid: string) => void;
    setLabel: (label: string) => void;
    setAlias: (alias: string) => void;
    setIssuer: (issuer: string) => void;
    setAccount: (account: string) => void;
    setStep: (step: NewCodeStep) => void;
    reset: () => void;
    load: (newCodeData: Code) => void;
}

export const useNewCodeStateStore = create<NewCodeStore>((set: any) => ({
    code: getDefaultNewCode(),
    step: NewCodeStep.Upload,
    setOrganizationUuid: (organizationUuid: string) => {
        set((state: any) => ({code: {...state.code, organization_uuid: organizationUuid}}));
    },
    setGroupUuid: (groupUuid: string) => {
        set((state: any) => ({code: {...state.code, group_uuid: groupUuid}}));
    },
    setLabel: (label: string) => {
        set((state: any) => ({code: {...state.code, label: label}}));
    },
    setAlias: (alias: string) => {
        set((state: any) => ({code: {...state.code, alias: alias}}));
    },
    setIssuer: (issuer: string) => {
        set((state: any) => ({code: {...state.code, issuer: issuer}}));
    },
    setAccount: (account: string) => {
        set((state: any) => ({code: {...state.code, account: account}}));
    },
    setStep: (step: NewCodeStep) => {
        set(() => ({step: step}));
    },
    reset: () => {
        set(() => ({
            code: getDefaultNewCode(),
            step: NewCodeStep.Upload,
        }));
    },
    load: (state: Code) => {
        set(() => ({code: state}));
    },
}));