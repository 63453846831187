import { useState, useEffect } from "react";
import { useImmutableRef } from "@/hooks/useImmutableRef.ts";
import {
    buildOptions,
    FetchRequest,
    ExecuteBodyFunction,
    ExecuteFunction,
    HttpMethod,
    HttpMethodsWithBody,
    RequestOptions,
    UseHttpResponse
} from "@/utils/fetch.ts"

// TODO: This really should be scoped to the hook somehow, as it is now
// it's global to all orgs, which is fine so long as it doesn't get out of wack
// and we don't even make simultaneous requests for different expired orgs

const useHttp = <T = any>(method: HttpMethod, organizationKey: string | undefined): UseHttpResponse => {
    if (organizationKey === undefined) {
        organizationKey = ""
    }
    const [status, setStatus] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>("null");
    const [data, setData] = useState<T | null>(null);

    const httpRequestRef = useImmutableRef<FetchRequest>(() => { return new FetchRequest(method, organizationKey) });

    useEffect(() => {
        if (httpRequestRef.getOrgKey() !== organizationKey) {
            httpRequestRef.setOrgKey(organizationKey || "");
        }
    }, [organizationKey]);

    const executePartial = async <T = any>(endpoint: string, options: RequestOptions | undefined): Promise<T> => {
        if (!options) {
            options = {}; // Ensure when undefined options are passed, that the empty object assigned is empty
        }

        setLoading(true);
        setStatus(0);
        setError(null);
        setData(null);

        options = buildOptions(options);
        try {
            const response = await httpRequestRef.makeWithRetries(endpoint, options);
            const json = await response.json();
            setStatus(response.status);
            if (response.ok) {
                setLoading(false);
                setData(json);
                return json;
            } else {
                setLoading(false);
                setError(json);
                throw json;
            }
        } catch (e) {
            let error = `error executing request: ${e}`;
            if (e instanceof Error) {
                error = e.message;
            } else if (typeof e === "string") {
                error = e;
            }
            console.error('Error making request:', e);
            setLoading(false);
            setError(error);
            setStatus(-1);
            throw e;
        }
    };

    let execute: ExecuteFunction | ExecuteBodyFunction;
    if (HttpMethodsWithBody.includes(method)) {
        execute = async <T = any>(endpoint: string, body: any, options: RequestOptions | undefined): Promise<T> => {
            options = buildOptions(options);
            if (body) {
                if (options.isJSON) {
                    options.body = JSON.stringify(body);
                    options.headers!['Content-Type'] = 'application/json';
                } else {
                    options.body = body;
                }
            }
            return await executePartial(endpoint, options);
        };
    } else {
        execute = executePartial;
    }

    return { httpOrgKey: httpRequestRef.getOrgKey(), status, loading, error, data, execute };
};

export const useHttpGet = <T = any>(orgKey?: string): UseHttpResponse<T, ExecuteFunction<T>> => {
    return useHttp<T>(HttpMethod.Get, orgKey) as UseHttpResponse<T, ExecuteFunction<T>>;
}
export const useHttpPost = <T = any>(orgKey?: string): UseHttpResponse<T, ExecuteBodyFunction<T>> => {
    return useHttp<T>(HttpMethod.Post, orgKey) as UseHttpResponse<T, ExecuteBodyFunction<T>>;
}
export const useHttpPut = <T = any>(orgKey?: string): UseHttpResponse<T, ExecuteBodyFunction<T>> => {
    return useHttp<T>(HttpMethod.Put, orgKey) as UseHttpResponse<T, ExecuteBodyFunction<T>>;
}
export const useHttpDelete = <T = any>(orgKey?: string): UseHttpResponse<T, ExecuteFunction<T>> => {
    return useHttp<T>(HttpMethod.Delete, orgKey) as UseHttpResponse<T, ExecuteFunction<T>>
}
export const useHttpPatch = <T = any>(orgKey?: string): UseHttpResponse<T, ExecuteBodyFunction<T>> => {
    return useHttp<T>(HttpMethod.Patch, orgKey) as UseHttpResponse<T, ExecuteBodyFunction<T>>;
}