import React from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import {Invite} from "@/types";
import clsx from "clsx";

const statuses: {[key: string]: string} = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

type InviteListProps = {
    onResend: (uuid: string) => void,
    onDelete: (uuid: string) => void,
    invites: Array<Invite>
}

export const InviteList: React.FC<InviteListProps> = ({invites, onResend, onDelete}) => {
    const renderInvite = (invite: Invite) => {
        const inviteDate = new Date(invite.created_at);

        return (
            <li key={invite.uuid} className="flex items-center justify-between gap-x-6 py-5">
                <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6">{invite.fullname}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5">
                        <p className="text-sm font-semibold leading-6">{invite.email}</p>
                    </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                    {/*<p className="whitespace-nowrap">*/}
                    {/*    Invited on <time dateTime={inviteDate.toLocaleString()}>{inviteDate.toLocaleDateString('en-US', {*/}
                    {/*    year: 'numeric',*/}
                    {/*    month: 'long',*/}
                    {/*    day: 'numeric'*/}
                    {/*})}</time>*/}
                    {/*</p>*/}


                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                        <p className={clsx(
                            statuses[invite.status],
                            'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                        )}>{invite.status}</p>
                        <p className="mt-1 text-xs leading-5">
                            Invited by {invite.inviter.fullname} on <time dateTime={inviteDate.toLocaleString()}>{inviteDate.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}</time>
                            {/*<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">*/}
                            {/*    <circle r={1} cx={1} cy={1}/>*/}
                            {/*</svg>*/}
                            {/*Invited by {invite.inviter.fullname}*/}
                        </p>
                    </div>
                    <Menu as="div" className="relative flex-none">
                        <MenuButton className="-m-2.5 block p-2.5 hover:text-gray-300">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5"/>
                        </MenuButton>
                        <MenuItems
                            transition
                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            { invite.status === "pending" && <MenuItem>
                                <a onClick={() => {
                                    onResend(invite.uuid)
                                }} className="block px-3 py-1 text-sm leading-6 data-[focus]:bg-gray-500 cursor-pointer">
                                    Resend invite<span className="sr-only">, {invite.fullname}</span>
                                </a>
                            </MenuItem>}
                            <MenuItem>
                                <a onClick={() => {
                                    onDelete(invite.uuid)
                                }}  className="block px-3 py-1 text-sm leading-6 data-[focus]:bg-gray-500 cursor-pointer">
                                    Delete<span className="sr-only">, {invite.fullname}</span>
                                </a>
                            </MenuItem>
                        </MenuItems>
                    </Menu>
                </div>
            </li>
        )
    }

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {invites.map((invite: Invite) => {
                return renderInvite(invite)
            })}
        </ul>
    )
}