import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Logo } from "@/components/atoms";
import { ArrowLeftCircleIcon } from "@heroicons/react/20/solid";

type LayoutPublicProps = {};


export const LayoutPublic: React.FC<LayoutPublicProps> = () => {
    const routerNav = useNavigate();
    return (
        <div className="bg-gradient-to-br from-primary-25 via-secondary-50 to-primary-25 dark:from-primary-700 dark:via-primary-900 dark:to-primary-700">
            <div className="h-full">
                <main>
                    <header className="max-w-prose mx-auto py-6 grid grid-cols-4 gap-4">
                        <div className="text-sm pt-5 text-primary-700 dark:text-primary-100" onClick={()=>routerNav(-1)}><ArrowLeftCircleIcon width={20} height={20} className="inline-block" /> Go Back</div>
                        <Logo className="col-span-2" />
                        <div className=""></div>
                    </header>
                    <div className="h-full min-h-screen p-4">
                        <Outlet/>
                    </div>
                </main>
            </div>
        </div>
    );
}