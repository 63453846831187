import React, {useEffect} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import { Switch } from '@headlessui/react'
import {PhotoIcon} from "@heroicons/react/20/solid";
import {useHttpGet, useHttpPost, useTranslation, useWorker} from "@/hooks";
import {EndpointOrganizationApps, EndpointOrganizationGroup, EndpointOrganizationGroupUpdate} from "@/constants"
import {useGroupStore} from "@/stores/groupState.ts";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {SimpleFileUpload} from "@/components/molecules";
import {Link} from "@/components/atoms";
import {App, AppIds, GroupType, OrganizationAppGroup, OrgOutlet, User} from "@/types";

type PageOrganizationGroupProps = {}

export const PageOrganizationGroup: React.FC<PageOrganizationGroupProps> = () => {
    const {t} = useTranslation();
    const [worker] = useWorker();
    const {group_uuid: groupUuid} = useParams();
    const {organization} = useOutletContext<OrgOutlet>();
    const {addNotice} = useAppStateStore();
    const {group: { label, description, settings: {icon}}, apps: groupApps, setLabel, setDescription, setIcon, setApp, resetApps, load} = useGroupStore();
    const {execute: getGroup, data: group, loading} = useHttpGet(organization?.key);
    const {execute: getApps, data: apps} = useHttpGet(organization?.key);
    const {execute: updateGroupApps} = useHttpPost(organization?.key);

    const appsGranted: {[key: string]: boolean} = {};
    groupApps?.forEach((app: OrganizationAppGroup) => {
        appsGranted[app.organization_app_uuid] = app.granted || false;
    })

    useEffect(() => {
        getApps(EndpointOrganizationApps(organization.uuid) + "?only_installed=true", {})
    }, [organization.uuid]);

    useEffect(() => {
        if (!organization || !groupUuid) {
            return;
        }
        getGroup(EndpointOrganizationGroup(organization.uuid, groupUuid), {}).then(load);
    }, [organization.uuid, groupUuid])

    const hasUnpersistedChanges = () => {
        if (!group) {
            return false;
        }
        if (group.label !== label || group.description !== description || group.settings.icon !== icon) {
            return true;
        }
        return groupApps?.some((app) => {
            return ((app.existing && app.granted !== app.original) || (!app.existing && app.granted))
        });
    }

    const saveGroupApps = async() => {
        if (!worker) {
            return;
        }
        const payload: any = {
            apps: []
        };


        for (const groupApp of Object.values(groupApps)) {
            if (!((groupApp.existing && groupApp.granted !== groupApp.original) || (!groupApp.existing && groupApp.granted))) {
                continue
            }
            if (groupApp.existing) {
                // if existing is true and we got here, it means that the app access was revoked
                payload.apps.push({
                    organization_app_uuid: groupApp.organization_app_uuid,
                    delete: true,
                });
                continue
            }

            // otherwise we are granting access to the app
            const currentApp = apps?.items?.find((app: App) => app.organization_app.uuid === groupApp.organization_app_uuid);

            let publicKey = currentApp.organization_app.public_key
            // generate credentials
            if (group.type === GroupType.Private) {
                publicKey = currentApp.organization_app.app_users[0].public_key;
            }
            const credentials = await worker.GrantGroupAccess(organization.key, group.current_user.credentials, publicKey);
            payload.apps.push({
                organization_app_uuid: groupApp.organization_app_uuid,
                credentials: credentials,
            });
        }

        if (group.label !== label) {
            payload["label"] = label;
        }

        if (group.description !== description) {
            payload["description"] = description;
        }

        if (group.settings.icon !== icon) {
            payload["icon"] = icon;
        }

        updateGroupApps(EndpointOrganizationGroupUpdate(organization.uuid, group.uuid), payload, {}).then(() => {
            addNotice({
                style:"success",
                type:"notify",
                easyDismiss: true,
                message: t("Group updated"),
            })
        }).catch((err) => {
            addNotice({
                style:"error",
                type:"notify",
                easyDismiss: true,
                message: t("Failed to update group: " + err.message),
            })
        });
    }

    const renderUserList = () => {
        if (!group) {
            // there should never be a time that the group has no users, it will always have at least one user
            return null;
        }
        if (loading) {
            return <li><span>{t("Loading group users...")}</span></li>
        }
        return (group.users).map((user: User) => {
            let avatar = "";
            if (user.settings.avatar_url) {
                avatar = user.settings.avatar_url;
            } else if (user.settings.avatar_data) {
                avatar = user.settings.avatar_data;
            }
            return (
                <li key={user.email} className="flex gap-x-4 px-3 py-5">
                    {avatar && <img alt={`${user.nickname}'s avatar`} src={avatar} className="h-12 w-12 flex-none rounded-full bg-gray-50"/>}
                    <div className="min-w-0">
                        <Link to={`../users/${user.uuid}`} className="block">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{user.fullname}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{user.nickname}</p>
                        </Link>
                    </div>
                </li>
            );
        });
    }

    const renderAppList = () => {
        if (!group || !apps) {
            return null;
        }
        return (
            <>
                {apps?.items?.filter((app: App) => { return app.id === AppIds.Slack}).map((app: App) => {
                    const orgApp = app.organization_app;
                    return (
                        <li key={app.id} className="flex items-center justify-between gap-x-6 py-5">
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{app.label}</p>
                                    {/*<p*/}
                                    {/*    className={classNames(*/}
                                    {/*        statuses[project.status],*/}
                                    {/*        'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',*/}
                                    {/*    )}*/}
                                    {/*>*/}
                                </div>
                            </div>
                            <div className="flex flex-none items-center gap-x-4">
                                <Switch
                                    checked={!!appsGranted[orgApp.uuid]}
                                    onChange={() => {
                                        setApp(orgApp.uuid, !appsGranted[orgApp.uuid])
                                    }}
                                    className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                                >
                                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                                </Switch>
                            </div>
                        </li>
                    );
                })}
            </>
        );
    }

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <div className="space-y-12">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("Edit Group")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t("Configure the settings specific to this group")}
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-full">
                            <label htmlFor="label" className="block text-sm font-medium leading-6 text-gray-900">
                                {t("Label")}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="label"
                                    name="label"
                                    type="text"
                                    value={label}
                                    onChange={(e) => setLabel(e.target.value)}
                                    autoComplete="off"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                {t("Description")}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="description"
                                    name="description"
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    autoComplete="off"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                {t("Icon")}
                            </label>
                            <div className="mt-2 flex items-center gap-x-3">
                                <div className="flex items-center justify-center w-16 h-16 rounded-full bg-blue-500">
                                    {!icon && (<PhotoIcon className="h-8 w-8 text-white"/>)}
                                    {icon && (<img src={icon} alt="Group Icon" className="h-full w-full rounded-full bg-gray-50"/>)}
                                </div>
                                <SimpleFileUpload label={t("Change")} onChange={(iconData) => setIcon(iconData)} />
                                {icon && (<button
                                    type="button"
                                    onClick={() => setIcon("")}
                                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    {t("Remove")}
                                </button>)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12 border-t pt-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("Group Users")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{t("A list of users with access to the group. Permissions are managed from the user settings")}</p>

                    <ul role="list" className="mt-2">
                        {renderUserList()}
                    </ul>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{t("App permissions")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t("Configure which apps have access to this group")}
                    </p>
                    <ul role="list" className="mt-2">
                        {renderAppList()}
                    </ul>
                    { hasUnpersistedChanges() && (
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" onClick={resetApps} className="text-sm font-semibold leading-6 text-gray-900">
                                {t("Cancel")}
                            </button>
                            <button
                                type="submit"
                                onClick={saveGroupApps}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {t("Save")}
                            </button>
                        </div>
                    )}
                </div>

            </div>
        </>
    );
}
