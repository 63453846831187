import React, {useState, useEffect} from "react";
import {LoadingCircle} from "@/components/atoms";

type CodeTimerProps = {
  nextAt: number; // UNIX timestamp of the time when 0 should be returned
  period: number; // Period in seconds
};

const TimeToPercent = (nextAt: number): number => {
  //TODO: Ensure to calculate periods and offsets to show multiple loading circles
  return  ((nextAt-Date.now())/30000)*100;
};

const Timer = (nextAt: number): string => {
  const now = Math.floor(Date.now()/1000);
  const next = Math.floor(nextAt/1000);
  const remainingSeconds = next - now;
  return remainingSeconds.toString();
};

export const CodeTimer: React.FC<CodeTimerProps> = ({ nextAt, period }) => {

  const [percent0, setPercent0] = useState<number>(() => TimeToPercent(nextAt));
  const [timer, setTimer] = useState<string>(() => Timer(nextAt));

  // Timer down the first period, reset for each period until no periods left.

  useEffect(() => {
    const tick = () => {
      setPercent0(TimeToPercent(nextAt));
      // setPercent1(TimeToPercent(nextAt, period, 1));
      // setPercent2(TimeToPercent(nextAt, period, 2));
      setTimer(Timer(nextAt));
      const now = Date.now();
      if (now > nextAt) {
        clearInterval(interval);
      }
    };
    // Update percentages immediately on mount
    tick();
    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval);
  }, [nextAt, period]);

  return (
    <div className="flex items-center justify-center">
      {nextAt && <div className="relative flex items-center justify-center w-14 h-14">
        {/* <div className="absolute w-full h-full">
          { cycle >= 2 && <LoadingCircle style="thin" color="indigo" percent={percent2} stroke={2.25} /> }
        </div>
        <div className="absolute w-4/5 h-4/5">
          { cycle >= 1 && <LoadingCircle style="thin" color="indigo" percent={percent1} stroke={2.5} /> }
        </div>  */}
        <div className="absolute w-3/5 h-3/5">
          <LoadingCircle style="thin" color="indigo" percent={percent0} stroke={3} />
        </div>
        <div className="flex items-center justify-center w-14 h-14">
          <span className="text-lg font-bold text-center text-indigo-900">{timer}</span>
        </div>
      </div>}
      
    </div>
  );
};
