import React, {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
// import {PhotoIcon} from "@heroicons/react/20/solid";
import {useHttpPost, useTranslation} from "@/hooks";
import {EndpointOrganizationUpdate} from "@/constants";
import {useAppStateStore} from "@/stores/keyforgeState.ts";
import {useOrganizationStore} from "@/stores/organizationState.ts";
import {OrgOutlet} from "@/types";
import {Button} from "@/components/atoms";
// import {SimpleFileUpload} from "@/components/molecules";

type PageAccountSettingsProps = {}

export const PageAccountSettings: React.FC<PageAccountSettingsProps> = () => {
    const {t} = useTranslation();
    const {organization} = useOutletContext<OrgOutlet>();
    const {organization: {label}, setLabel, load} = useOrganizationStore();
    const {addNotice} = useAppStateStore();
    const {execute: updateOrganization, data: updatedOrganization} = useHttpPost(organization.key);
    // if updatedOrganization is set and matches the loaded organization, use its state as the currentOrg, otherwise use the initially loaded org state
    const currentOrg = (updatedOrganization && updatedOrganization.uuid === organization.uuid) ? updatedOrganization : organization;

    useEffect(() => {
        load(organization);
    }, [organization.uuid])

    const hasUnpersistedChanges = () => {
        if (!organization) {
            return false;
        }
        if (currentOrg.label !== label) {
            return true;
        }
        // if (currentOrg.settings.logo !== logo) {
        //     return true;
        // }
        return false;
    }

    const handleUpdate = () => {
        let payload = {};
        if (currentOrg.label !== label) {
            payload = {...payload, label};

        }
        // if (currentOrg.settings.logo !== logo) {
        //     payload = {...payload, settings: { ...currentOrg.settings, logo}};
        // }
        updateOrganization(EndpointOrganizationUpdate(organization.uuid), payload, {}).then((result) => {
            addNotice({
                style:"success",
                type:"notify",
                easyDismiss: true,
                message: t("Organization updated!"),
            })
            load(result); // update the state with the latest data
        }).catch((e: any) => {
            console.log(e);
            addNotice({
                style:"error",
                type:"notify",
                easyDismiss: true,
                message: t("Failed to update organization: ") + e.message,
            })
        })
    }

    const handleRemoveOrg = () => {
        alert("WIP - Not fully implemented");
        // remove organization from worker
        // remove org access token
        // forget any org login session (ie unlocked private key)
        // issue logout command
    }

    return (
        <div>
            <div>
                <h1 className="text-3xl font-bold tracking-tight text-white-900 sm:text-4xl">
                    {t("Organization Settings")}
                </h1>
            </div>
            <div className="container mx-auto bg-white shadow sm:rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">{t("General Settings")}</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            {t("Update the basic settings like the organization name")}
                        </p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="label" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t("Name")}
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="label"
                                        name="label"
                                        type="text"
                                        value={label}
                                        onChange={(e) => setLabel(e.target.value)}
                                        autoComplete="off"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            {/*<div className="col-span-full">*/}
                            {/*    <label htmlFor="logo" className="block text-sm font-medium leading-6 text-gray-900">*/}
                            {/*        {t("Logo")}*/}
                            {/*    </label>*/}
                            {/*    <div className="mt-2 flex items-center gap-x-3">*/}
                            {/*        <div className="flex items-center justify-center w-16 h-16 rounded-full bg-blue-500">*/}
                            {/*            {!logo && (<PhotoIcon className="h-8 w-8 text-white"/>)}*/}
                            {/*            {logo && (<img src={logo} alt="Organization Logo" className="h-full w-full rounded-full bg-gray-50"/>)}*/}
                            {/*        </div>*/}
                            {/*        <SimpleFileUpload label={t("Change")} onChange={(logoData) => setLogo(logoData)} />*/}
                            {/*        {logo && (<button*/}
                            {/*            type="button"*/}
                            {/*            onClick={() => setLogo("")}*/}
                            {/*            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
                            {/*        >*/}
                            {/*            {t("Remove")}*/}
                            {/*        </button>)}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="pt-2">
                        {hasUnpersistedChanges() && (
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <button type="button" onClick={() => load(organization)} className="text-sm font-semibold leading-6 text-gray-900">
                                    {t("Cancel")}
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleUpdate}
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    {t("Save")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="container mx-auto bg-white shadow sm:rounded-lg mt-4">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-base font-semibold leading-6">{t("Remove Organization")}</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>{t("Removing the organization will log you out and remove the organization from this client, and forget all settings including your secret key. It will not affect the organization itself or the logged in status for any other user. You will need to a full client setup to access your codes from this device")}</p>
                    </div>
                    <div className="mt-5">
                        <Button onClick={() => handleRemoveOrg()}
                                type="button"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                            {t("Remove")}
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    );
}
