import React from "react"
import {ArrowPathIcon} from "@heroicons/react/24/solid";

interface LoadingIconProps {
    className?: string
}

export const LoadingIcon: React.FC<LoadingIconProps> = ({className}) => {
    if (!className) {
        className = "text-gray-400"
    }
    return (
        <ArrowPathIcon aria-hidden="true" className="inline-block animate-spin h-5 w-5" viewBox="0 0 24 24"/>
    )
}