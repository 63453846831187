import {create} from "zustand";
import {getDefaultOrganization, Organization} from "@/types";

export type OrganizationStateStore = {
    organization: Organization;
    setLabel: (label: string) => void;
    setLogo: (logo: any) => void;
    setBgColor: (color: string) => void;
    reset: () => void;
    load: (state: Organization) => void;
};

export const useOrganizationStore =  create<OrganizationStateStore>((set: any) => ({
    organization: getDefaultOrganization(),
    setLabel: (label: string) => {
        set((state: any) => ({organization: {...state.organization, label: label}}));
    },
    setLogo: (logo: any) => {
        set((state: any) => ({organization: {...state.organization, settings: {...state.organization.settings, logo: logo}}}));
    },
    setBgColor: (color: string) => {
        set((state: any) => ({organization: {...state.organization, settings: {...state.organization.settings, bg_color: color}}}));
    },
    reset: () => {
        set(() => ({organization: getDefaultOrganization()}));
    },
    load: (state: Organization) => {
        set(() => ({organization: state}));
    },
}));