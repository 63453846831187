import React from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {useTranslation} from "@/hooks";
import {UrlOrganizationSettings} from "@/constants";

import {Admonition, AdmonitionType, Link} from "@/components/atoms";
import {RecoveryDocument} from "@/components/organisms/RecoveryDocument";

type AccountDetailsProps = {
    orgLabel: string;
    orgKey: string;
    email: string;
    heading: string;
    secretKey: string;
};

export const AccountDetails: React.FC<AccountDetailsProps> = ({orgLabel, orgKey, email, secretKey, heading}) => {
    const {t} = useTranslation();

    const createRecoveryDocPdf = (orgKey: string, secretKey: string) => {
        return <PDFDownloadLink document={<RecoveryDocument orgKey={orgKey} secretKey={secretKey}/>} fileName="keyforge-recovery-document.pdf">
            {({blob, url, loading, error}) => {
                console.log({blob, url, loading, error})
                return loading ? 'Loading document...' : 'Download PDF'
            }}
        </PDFDownloadLink>
    }

    return (
        <div className="overflow-hidden border rounded-lg bg-opacity-80 shadow-sm bg-white dark:bg-primary-900 text-primary-700 dark:text-primary-100 border-primary-50 dark:border-primary-500">
            <div className="px-7 py-5 sm:p-6">
                <h1 className="text-2xl font-bold text-center">{heading}</h1>
            </div>
            <div className="px-24 py-6">
                <p>{t("You are on your way to using Key Forge to store your MFA codes in the cloud! Below is an overview on your new account information, ensure that it is accurate and keep a copy for your records.")}</p>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t("Organization Name")}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-mono bg-slate-100 px-2 py-1 rounded-sm">{orgLabel}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t("Organization Key")}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-mono bg-slate-100 px-2 py-1 rounded-sm">{orgKey}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t("Account Email")}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-mono bg-slate-100 px-2 py-1 rounded-sm">{email}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t("Secret Key")}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-mono bg-slate-100 px-2 py-1 rounded-sm">
                                {secretKey}
                            </dd>
                            <div className="mt-1 col-start-2 col-end-4 col-span-2 sm:mt-0">
                                <Admonition
                                    type={AdmonitionType.Danger}>{t("<b>Note: Your secret key should NEVER be shared</b> and should ONLY be known by you. This key is only used when logging into Key Forge on a new device. Our team will NEVER ask you for this key. Keep it secret! Keep it safe!")}</Admonition>
                            </div>
                        </div>
                    </dl>
                </div>
                <div className="border-b border-gray-200 bg-slate-100 px-4 py-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                        <div className="ml-4 mt-2">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">{t("Download your Account Recovery Document")}</h3>
                        </div>
                        <div className="ml-4 mt-2 flex-shrink-0">
                            <button
                                type="button"
                                className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >{createRecoveryDocPdf(orgKey, secretKey)}</button>
                        </div>
                    </div>
                    <div className="flex-shrink-0 pt-4 pr-2">
                        <p className="text-md text-slate-800 pb-1">{t("Download the information above as a PDF.")}</p>
                        <p className="text-md text-slate-800">{t("Ensure to keep it safe, as losing your Secret Key it could result in data loss. Key Forge does not store your secret key. If you lose it, it will be lost forever.")}</p>
                    </div>
                </div>
                <div className="px-7 py-5 sm:p-6">
                    <p>{t("Visit the knowledge base for guides, FAQs, and more to get started. Or, when you're ready, you can continue view your new organization.")}</p>
                    <div className="mt-4 flex justify-between">
                        <Link
                            className="flex-initial gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            to={`#`}>{t("View the Knowledge Base")}</Link>
                        <Link
                            className="inline-flex gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            to={UrlOrganizationSettings(orgKey)}>{t("View your New Organization")}: {orgLabel}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}